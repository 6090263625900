import { createContext, useContext, useState } from 'react';
import { IMenuItem } from '../app.models';
import { MENU_ITENS } from '../app.settings';

interface IContext {
  menuLeftIsOpen: boolean;
  setMenuLeftIsOpen: (menuLeftIsOpen: boolean) => void;
  menuItems: IMenuItem[];
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [menuLeftIsOpen, setMenuLeftIsOpen] = useState<boolean>(true);
  const menuItems = MENU_ITENS;

  return (
    <Context.Provider
      value={{
        menuLeftIsOpen,
        setMenuLeftIsOpen,
        menuItems,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useLayout = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useLayout, Provider as LayoutProvider };

import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

export const ErrorNotification = (template: string) =>
  Toastify({
    text: template,
    duration: 3000,
    newWindow: true,
    close: false,
    gravity: 'top', // `top` or `bottom`
    position: 'left', // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    escapeMarkup: false,
    style: {
      background: 'linear-gradient(to right, #ff3333, #ff5555)',
      borderRadius: '10px',
    },
    onClick: () => null, // Callback after click
  }).showToast();

export const SuccessNotification = (template: string) =>
  Toastify({
    text: template,
    duration: 3000,
    newWindow: true,
    close: false,
    gravity: 'top', // `top` or `bottom`
    position: 'left', // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    escapeMarkup: false,
    style: {
      background: 'linear-gradient(to right, #acf701, #acf755)',
      borderRadius: '10px',
    },
    onClick: () => null, // Callback after click
  }).showToast();

export const InfoNotification = (template: string) =>
  Toastify({
    text: template,
    duration: 3000,
    newWindow: true,
    close: false,
    gravity: 'top', // `top` or `bottom`
    position: 'left', // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    escapeMarkup: false,
    style: {
      background: 'linear-gradient(to right, #2986cc, #2986aa)',
      borderRadius: '10px',
    },
    onClick: () => null, // Callback after click
  }).showToast();

export const WarningNotification = (template: string) =>
  Toastify({
    text: template,
    duration: 3000,
    newWindow: true,
    close: false,
    gravity: 'top', // `top` or `bottom`
    position: 'left', // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    escapeMarkup: false,
    style: {
      background: 'linear-gradient(to right, #ffff70, #ffdc70)',
      borderRadius: '10px',
    },
    onClick: () => null, // Callback after click
  }).showToast();

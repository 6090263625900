import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import LogoName from '../../assets/svg/logo_name.svg';
import { useAuth } from '../../Auth/Context';
import { ReactComponent as Loading } from '../../assets/svg/loading.svg';

interface IForm {
  email: string;
  senha: string;
}

export function Login() {
  const { signIn, loading } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>();

  const submit = (data: IForm) => {
    const { email, senha } = data;
    signIn(email, senha);
  };

  return (
    <section className="bg-gradient-to-b from-primary via-primary to-secondary">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
              <img className="h-16" src={LogoName} alt="logo-name" />
            </div>
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Acesso ao sistema
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(submit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  {...register('email', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Email inválido',
                    },
                  })}
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="nome@email.com"
                />
                {errors?.email && (
                  <span className="text-red-500 text-xs">{errors.email.message}</span>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Senha
                </label>
                <input
                  type="password"
                  {...register('senha', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {errors?.senha && (
                  <span className="text-red-500 text-xs">{errors.senha.message}</span>
                )}
              </div>
              <button
                type="submit"
                className={classNames(
                  'flex justify-center',
                  'w-full rounded-lg py-2 bg-primary text-white',
                  'focus:bg-primary-focus',
                  'hover:bg-primary-focus',
                  'disabled:bg-slate-500',
                )}
                disabled={loading}
              >
                {loading ? <Loading className="animate-spin h-5 w-5" /> : <span>Entrar</span>}
              </button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Somente pessoas credenciadas pode acessar o sistema!
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

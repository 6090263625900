import classNames from 'classnames';
import { useEffect, useState, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../../app.settings';
import { BreadcrumbsComp } from '../../../Components/BreadcrumbsComp';
import { ButtonBackComp } from '../../../Components/ButtonBackComp';
import { ButtonComp } from '../../../Components/ButtonComp';
import { ButtonDeleteComp } from '../../../Components/ButtonDeleteComp';
import LoadingPageComp from '../../../Components/LoadingPageComp';
import { IUsuario } from './model';
import { getById, save, remove, updPassword, UpdatePassword } from './service';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.br';
// import { Switch } from '@headlessui/react';
import { Label, Radio } from 'flowbite-react';
import ModalUpdatePassword from '../../Profile/ModalUpdatePassword';
import { ErrorNotification, SuccessNotification } from '../../../app.notification';

export default function UsuarioForm() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<IUsuario>();

  const currentId = watch('id', undefined);

  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [locationState, setLocationState] = useState<any>();

  const loadForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (record: Record<string, any>) => {
      const entries = Object.entries(record);
      entries.forEach(([key, value]) => {
        switch (key) {
          default:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(key as any, value);
        }
      });
    },
    [setValue],
  );

  const init = useCallback(async () => {
    if (params?.id) {
      setLoading(true);
      getById(params.id)
        .then(loadForm)
        .finally(() => setLoading(false));
    } else {
      // insere campos default
    }
  }, [loadForm, params.id]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setLocationState(location.state);
  }, [location]);

  const navigateToList = async (sleep: number) => {
    await new Promise((t) => setTimeout(t, sleep)).then(() => {
      navigate(PATHS.usuario, { state: locationState });
    });
  };

  const submit = (data: IUsuario) => {
    save(data).then(loadForm);
    // navigateToList(1000);
  };

  const del = () => {
    const id = getValues('id').toString();
    if (id) {
      remove(id).then(loadForm);
      navigateToList(1000);
    }
  };

  const updatePassword = async (newPassword: string) => {
    const currentUser = getValues();
    const data: UpdatePassword = {
      id: String(currentUser.id),
      email: currentUser.email,
      senha: newPassword,
      perfil: currentUser.perfil,
    };
    if (!data) return;
    // console.log('🚀 ~ file: index.tsx:54 ~ updatePassword ~ data:', data);
    updPassword(data)
      ?.then(() => {
        SuccessNotification('Senha alterada com sucesso');
      })
      .catch((err) => {
        console.log('🚀 ~ err:', err);
        ErrorNotification('Erro ao alterar senha');
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="divide-y space-y-4">
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp
          title={params?.id ? 'Alterar' : 'Inserir'}
          paths={[
            {
              label: 'Usuarios',
              onClick: () => navigateToList(0),
            },
          ]}
        />
        <div className="flex space-x-2">
          <ButtonBackComp label="Voltar" type="button" onClick={() => navigateToList(0)} />
          {params?.id && (
            <ButtonDeleteComp confirm={true} label="Excluir" type="button" onClick={del} />
          )}
          <ButtonComp label="Salvar" type="submit" />
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingPageComp />
        ) : (
          <div className="max-w-lg space-y-2">
            <label htmlFor="nome" className="flex flex-col">
              <span>Nome</span>
              <input
                id="nome"
                type="text"
                className="rounded-lg"
                placeholder="Nome"
                {...register('nome', {
                  required: {
                    value: true,
                    message: '(*) Campo requerido',
                  },
                })}
              />
              {errors?.nome && (
                <span className="text-red-500 text-xs">{errors?.nome?.message}</span>
              )}
            </label>
            <label htmlFor="email" className="flex flex-col">
              <span>Email</span>
              <input
                id="email"
                type="email"
                className="rounded-lg"
                placeholder="Email"
                {...register('email', {
                  required: {
                    value: true,
                    message: '(*) Campo requerido',
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email inválido',
                  },
                })}
              />
              {errors?.email && (
                <span className="text-red-500 text-xs">{errors?.email?.message}</span>
              )}
            </label>
            <label htmlFor="telefone" className="flex flex-col">
              <span>Telefone</span>
              <Controller
                control={control}
                name={'telefone'}
                rules={{
                  required: {
                    value: true,
                    message: '(*) Campo requerido',
                  },
                }}
                render={({ field }) => (
                  <Cleave
                    {...field}
                    options={{ phone: true, phoneRegionCode: 'br' }}
                    className={classNames('form-input peer w-full rounded-lg')}
                  />
                )}
              />

              {errors?.telefone && (
                <span className="text-red-500 text-xs">{errors?.telefone?.message}</span>
              )}
            </label>
            {!currentId && (
              <label htmlFor="senha" className="flex flex-col">
                <span>Senha</span>
                <input
                  id="senha"
                  type="password"
                  className="rounded-lg"
                  placeholder="Senha"
                  {...register('senha', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.senha && (
                  <span className="text-red-500 text-xs">{errors?.senha?.message}</span>
                )}
              </label>
            )}
            <label htmlFor="telefone" className="flex flex-col">
              <span>Perfil</span>
              <Controller
                control={control}
                name={'perfil'}
                rules={{
                  required: {
                    value: true,
                    message: '(*) Campo requerido',
                  },
                }}
                render={({ field }) => (
                  <>
                    <div className="flex justify-between items-center space-x-2">
                      {/* <Switch
                      {...field}
                      checked={field.value === 'ADMIN'}
                      onChange={(e: boolean) =>
                        e ? field.onChange('ADMIN') : field.onChange('USER')
                      }
                      className={`${field.value === 'ADMIN' ? 'bg-primary' : 'bg-primary/50'}
          relative inline-flex h-[30px] w-[67px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={`${field.value === 'ADMIN' ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch> */}
                      <div className="flex items-center gap-2">
                        <Radio
                          id="USER"
                          checked={field.value === 'USER'}
                          onChange={() => field.onChange('USER')}
                        />
                        <Label color={field.value === 'USER' ? 'primary' : ''} htmlFor="USER">
                          USUÁRIO
                        </Label>
                      </div>
                      <div className="flex items-center gap-2">
                        <Radio
                          id="ADMIN"
                          checked={field.value === 'ADMIN'}
                          onChange={() => field.onChange('ADMIN')}
                        />
                        <Label color={field.value === 'ADMIN' ? 'primary' : ''} htmlFor="ADMIN">
                          ADMINISTRADOR
                        </Label>
                      </div>
                    </div>
                  </>
                )}
              />

              {errors?.telefone && (
                <span className="text-red-500 text-xs">{errors?.telefone?.message}</span>
              )}
            </label>
            {currentId && (
              <label htmlFor="password" className="flex flex-col">
                <ModalUpdatePassword id="password" action={updatePassword} label="Alterar senha" />
              </label>
            )}
          </div>
        )}
      </div>
    </form>
  );
}

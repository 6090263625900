export const EXCEL_FIELDS = [
  {
    desc: 'Nome',
    value: 'nome',
  },
  {
    desc: 'Data Nascimento',
    value: 'nascimento',
  },
  {
    desc: 'Exercício',
    value: 'exercicio',
  },
  {
    desc: 'CPF',
    value: 'cpf',
  },
  {
    desc: 'RG',
    value: 'rgNro',
  },
  {
    desc: 'RG Orgão Emissor',
    value: 'rgOrgaoEmissor',
  },
  {
    desc: 'Naturalidade',
    value: 'naturalidade',
  },
  {
    desc: 'Cargo',
    value: 'cargo',
  },
  {
    desc: 'Função',
    value: 'funcao',
  },
  {
    desc: 'Sintuação Funcional',
    value: 'situacaoFuncional',
  },
  {
    desc: 'Vínculo Funcional',
    value: 'vinculoFuncional',
  },
  {
    desc: 'Filiado',
    value: 'filiado',
  },
  {
    desc: 'Concurso',
    value: 'concurso',
  },
  {
    desc: 'Data Sindicato',
    value: 'sindicatoData',
  },
  {
    desc: 'Endereço',
    value: 'endereço',
  },
  {
    desc: 'Complemento',
    value: 'complemento',
  },
  {
    desc: 'Bairro',
    value: 'bairro',
  },
  {
    desc: 'Cidade',
    value: 'cidade',
  },
  {
    desc: 'CEP',
    value: 'cep',
  },
  {
    desc: 'Cel1',
    value: 'cel1',
  },
  {
    desc: 'Cel2',
    value: 'cel2',
  },
  {
    desc: 'Cel3',
    value: 'cel3',
  },
  {
    desc: 'Tel Fixo',
    value: 'fixo',
  },
  {
    desc: 'Email Pessoal',
    value: 'email',
  },
  {
    desc: 'Email Institucional',
    value: 'emailInstitucional',
  },
  {
    desc: 'Graduação',
    value: 'cursoGraduacao',
  },
  {
    desc: 'Especialidade',
    value: 'especialidade',
  },
  {
    desc: 'Grau de Instrução',
    value: 'grauInstrucao',
  },
  {
    desc: 'Estado Civil',
    value: 'estadoCivil',
  },
  {
    desc: 'Instituição',
    value: 'instituicao',
  },
  {
    desc: 'UF',
    value: 'uf',
  },
  {
    desc: 'Matricula',
    value: 'matricula',
  },
  {
    desc: 'Lotação',
    value: 'lotacao',
  }
].sort((a, b) => (a.desc > b.desc ? 1 : -1));

/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { PATHS } from '../app.settings';

interface Props {
  title: string;
  path: string;
}

export function AppMenu({ title, path }: Props) {
  const location = useLocation();
  return (
    <div className="flex items-center h-full px-3">
      <div className="relative inline-block text-left">
        <NavLink
          to={path}
          className={({ isActive }) =>
            classNames(
              'flex items-center hover:text-secondary-focus text-sm',
              isActive && location.pathname === PATHS.dashboard ? 'text-secondary' : 'text-white',
            )
          }
        >
          <span>{title}</span>
          {/* <ChevronDownIcon
            className={classNames('ml-2 -mr-1 h-5 w-5 hover:text-secondary-focus ')}
            aria-hidden="true"
          /> */}
        </NavLink>
      </div>
    </div>
  );
}

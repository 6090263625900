import { Control, Controller, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import ComboboxComp from '../../Components/ComboboxComp';
import { useAssociado } from './context';
import { IAssociado } from './model';

interface Props {
  register: UseFormRegister<IAssociado>;
  control: Control<IAssociado>;
  errors: Partial<FieldErrorsImpl<IAssociado>>;
}
export default function FormAssociadoDadosFuncionais({ register, control, errors }: Props) {
  const { auxTables } = useAssociado();
  return (
    <div className="space-y-6">
      {/* linha 1 */}
      <div className="grid grid-cols-12 gap-4">
        {/* matricula */}
        <label htmlFor="matricula" className="flex flex-col w-full col-span-2">
          <span>Matrícula</span>
          <input
            id="matricula"
            type="text"
            className="rounded-lg"
            placeholder="Matrícula"
            {...register('matricula', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.matricula && (
            <span className="text-red-500 text-xs">{errors?.matricula?.message}</span>
          )}
        </label>

        {/* matriculaDv */}
        <label htmlFor="matriculaDv" className="flex flex-col w-full col-span-2">
          <span>Matrícula DV</span>
          <input
            id="matriculaDv"
            type="text"
            className="rounded-lg"
            placeholder="Matrícula DV"
            {...register('matriculaDv', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.matriculaDv && (
            <span className="text-red-500 text-xs">{errors?.matriculaDv?.message}</span>
          )}
        </label>

        {/* concurso */}
        <label htmlFor="concurso" className="col-span-2 flex flex-col z-30 relative">
          <span>Concurso</span>
          <Controller
            control={control}
            name={'concurso'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  auxTables?.Concurso?.map((el: any) => ({
                    id: el.id,
                    desc: el.descricao,
                    isVisible: el.isVisible,
                  })) || []
                }
              />
            )}
          />

          {errors?.concurso && (
            <span className="text-red-500 text-xs">{errors?.concurso?.message}</span>
          )}
        </label>

        {/* exercicio */}
        <label htmlFor="exercicio" className="flex flex-col w-full col-span-2">
          <span>Exercício</span>
          <input
            id="exercicio"
            type="date"
            className="rounded-lg"
            placeholder="Exercício"
            {...register('exercicio', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.exercicio && (
            <span className="text-red-500 text-xs">{errors?.exercicio?.message}</span>
          )}
        </label>

        {/* situacaoFuncional */}
        <label htmlFor="situacaoFuncional" className="col-span-4 flex flex-col z-30 relative">
          <span>Situação Funcional</span>
          <Controller
            control={control}
            name={'situacaoFuncional'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  auxTables?.SituacaoFuncional?.map((el: any) => ({
                    id: el.id,
                    desc: el.descricao,
                    isVisible: el.isVisible,
                  })) || []
                }
              />
            )}
          />

          {errors?.situacaoFuncional && (
            <span className="text-red-500 text-xs">{errors?.situacaoFuncional?.message}</span>
          )}
        </label>
      </div>

      {/* linha 2 */}
      <div className="grid grid-cols-12 gap-4">
        {/* vinculoFuncional */}
        <label htmlFor="vinculoFuncional" className="col-span-4 flex flex-col z-30 relative">
          <span>Vínculo Funcional</span>
          <Controller
            control={control}
            name={'vinculoFuncional'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  auxTables?.Vinculo?.map((el: any) => ({
                    id: el.id,
                    desc: el.descricao,
                    isVisible: el.isVisible,
                  })) || []
                }
              />
            )}
          />

          {errors?.vinculoFuncional && (
            <span className="text-red-500 text-xs">{errors?.vinculoFuncional?.message}</span>
          )}
        </label>

        {/* funcao */}
        <label htmlFor="funcao" className="col-span-4 flex flex-col w-full">
          <span>Função</span>
          <input
            id="funcao"
            type="text"
            className="rounded-lg"
            placeholder="Função"
            {...register('funcao', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.funcao && (
            <span className="text-red-500 text-xs">{errors?.funcao?.message}</span>
          )}
        </label>

        {/* lotacao */}
        <label htmlFor="lotacao" className="col-span-4 flex flex-col z-20 relative">
          <span>Lotação Atual</span>
          <Controller
            control={control}
            name={'lotacao'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  auxTables?.Lotacao?.map((el: any) => ({
                    id: el.id,
                    desc: el.local,
                    isVisible: true,
                  })) || []
                }
              />
            )}
          />

          {errors?.lotacao && (
            <span className="text-red-500 text-xs">{errors?.lotacao?.message}</span>
          )}
        </label>
      </div>

      {/* linha 3 */}
      <div className="grid grid-cols-12 gap-4">
        {/* grauInstrucao */}
        <label htmlFor="grauInstrucao" className="col-span-3 flex flex-col z-auto relative">
          <span>Grau de Instrução</span>
          <Controller
            control={control}
            name={'grauInstrucao'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  auxTables?.GrauInstrucao?.map((el: any) => ({
                    id: el.id,
                    desc: el.descricao,
                    isVisible: el.isVisible,
                  })) || []
                }
              />
            )}
          />

          {errors?.grauInstrucao && (
            <span className="text-red-500 text-xs">{errors?.grauInstrucao?.message}</span>
          )}
        </label>

        {/* cursoGraduacao */}
        <label htmlFor="cursoGraduacao" className="flex flex-col w-full col-span-3">
          <span>Curso Graduação</span>
          <input
            id="cursoGraduacao"
            type="text"
            className="rounded-lg"
            placeholder="Curso Graduação"
            {...register('cursoGraduacao', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.cursoGraduacao && (
            <span className="text-red-500 text-xs">{errors?.cursoGraduacao?.message}</span>
          )}
        </label>

        {/* especialidade */}
        <label htmlFor="especialidade" className="flex flex-col w-full col-span-3">
          <span>Especialidade</span>
          <input
            id="especialidade"
            type="text"
            className="rounded-lg"
            placeholder="Especialidade"
            {...register('especialidade', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.especialidade && (
            <span className="text-red-500 text-xs">{errors?.especialidade?.message}</span>
          )}
        </label>

        {/* interesseEducacional */}
        <label htmlFor="interesseEducacional" className="col-span-3 flex flex-col z-auto relative">
          <span>Interesse Educacional</span>
          <Controller
            control={control}
            name={'interesseEducacional'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  auxTables?.InteresseEducacional?.map((el: any) => ({
                    id: el.id,
                    desc: el.descricao,
                    isVisible: el.isVisible,
                  })) || []
                }
              />
            )}
          />

          {errors?.interesseEducacional && (
            <span className="text-red-500 text-xs">{errors?.interesseEducacional?.message}</span>
          )}
        </label>
      </div>
    </div>
  );
}

import { createContext, useContext, useState, useEffect } from 'react';
import { getAllUf, IUf } from './app.service';
import { useAuth } from './Auth/Context';

interface IContext {
  ufs: IUf[];
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const { authenticated } = useAuth();
  const [ufs, setUfs] = useState<IUf[]>([]);

  useEffect(() => {
    if (authenticated) getAllUf().then((data) => data && setUfs(data));
  }, [authenticated]);

  return (
    <Context.Provider
      value={{
        ufs,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useApp = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useApp, Provider as AppProvider };

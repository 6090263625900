import { useState, useEffect, useRef } from 'react';
import { Checkbox, Label } from 'flowbite-react';
import classNames from 'classnames';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (record: Record<string, any>) => void;
  dataKey: string;
  dataDesc: string;
  clearFilter?: number;
  inputType?: string;
  maxInputNumber?: number;
  minInputNumber?: number;
  inputHint?: string;
}

const CLASS_INPUT = 'rounded-lg text-xs px-2 py-1 disabled:text-slate-500';

export default function CheckFilterInput({
  action,
  dataKey,
  dataDesc,
  clearFilter,
  inputType = 'text',
  maxInputNumber = 999999999999,
  minInputNumber = 0,
  inputHint = '',
}: Props) {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (active && value) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = new Object();
      obj[dataKey] = value;
      action({ ...obj });
    } else {
      action({});
    }
  }, [action, active, dataKey, value]);

  const lastCount = useRef(0);

  useEffect(() => {
    if (!active) return;
    if (!clearFilter) return;
    const current = lastCount.current;
    if (clearFilter !== current) {
      setActive(false);
      lastCount.current = clearFilter;
    }
  }, [active, clearFilter]);

  useEffect(() => {
    if (!clearFilter) return;
    lastCount.current = clearFilter;
  }, [clearFilter]);

  return (
    <div
      className={classNames(
        'flex flex-col space-y-2',
        'p-2 rounded-lg',
        active ? 'bg-slate-400 ' : 'bg-slate-200',
        'text-slate-900',
        active ? 'font-bold' : '',
      )}
    >
      <div className="flex items-center gap-2">
        <Checkbox
          id="check"
          defaultChecked={false}
          checked={active}
          onChange={() => setActive(!active)}
        />
        <Label htmlFor="check" className={classNames(active === false && 'text-slate-500')}>
          {dataDesc}
        </Label>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="w-full space-y-2 text-xs">
          <div className="flex justify-between">
            {inputHint && <span>{inputHint}</span>}
            <input
              className={CLASS_INPUT}
              type={inputType}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={!active}
              max={maxInputNumber}
              min={minInputNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

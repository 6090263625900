import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
// eslint-disable-next-line no-duplicate-imports
import { Controller } from 'react-hook-form';
import ComboboxComp from '../../Components/ComboboxComp';
import UfComboboxComp from '../../Components/UfComboboxComp';

import { useAssociado } from './context';
import { IAssociado } from './model';

import InputMask from 'react-input-mask';

interface Props {
  register: UseFormRegister<IAssociado>;
  control: Control<IAssociado>;
  errors: Partial<FieldErrorsImpl<IAssociado>>;
}

export default function FormAssociadoGeral({ register, control, errors }: Props) {
  const { auxTables } = useAssociado();
  return (
    <div className="space-y-6">
      {/* linha 1 */}
      <div className="grid grid-cols-12 gap-4">
        {/* nome */}
        <label htmlFor="nome" className="flex flex-col w-full col-span-6">
          <span>Nome</span>
          <input
            id="nome"
            type="text"
            className="rounded-lg"
            placeholder="Nome"
            {...register('nome', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.nome && <span className="text-red-500 text-xs">{errors?.nome?.message}</span>}
        </label>

        {/* nascimento */}
        <label htmlFor="nascimento" className="flex flex-col w-full col-span-2">
          <span>Nascimento</span>
          <input
            id="nascimento"
            type="date"
            className="rounded-lg"
            placeholder="Nascimento"
            {...register('nascimento', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.nascimento && (
            <span className="text-red-500 text-xs">{errors?.nascimento?.message}</span>
          )}
        </label>

        {/* naturalidade */}
        <label htmlFor="naturalidade" className="flex flex-col w-full col-span-4">
          <span>Naturalidade</span>
          <input
            id="naturalidade"
            type="text"
            className="rounded-lg"
            placeholder="Naturalidade"
            {...register('naturalidade', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.naturalidade && (
            <span className="text-red-500 text-xs">{errors?.naturalidade?.message}</span>
          )}
        </label>
      </div>

      {/* linha 2 */}
      <div className="grid grid-cols-12 gap-4">
        {/* sexo */}
        <label htmlFor="sexo" className="flex flex-col w-full col-span-2">
          <span>Sexo</span>
          <select
            id="sexo"
            className="rounded-lg form-select"
            placeholder="Sexo"
            {...register('sexo', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          >
            <option value={''}>Selecione ...</option>
            <option value={'M'}>Masculino</option>
            <option value={'F'}>Feminino</option>
          </select>
          {errors?.sexo && <span className="text-red-500 text-xs">{errors?.sexo?.message}</span>}
        </label>

        {/* estadoCivil */}
        <label htmlFor="estadoCivil" className="col-span-2 flex flex-col z-auto relative">
          <span>Estado Civil</span>
          <Controller
            control={control}
            name={'estadoCivil'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  auxTables?.EstadoCivil?.map((el: any) => ({
                    id: el.id,
                    desc: el.descricao,
                    isVisible: el.isVisible,
                  })) || []
                }
              />
            )}
          />

          {errors?.estadoCivil && (
            <span className="text-red-500 text-xs">{errors?.estadoCivil?.message}</span>
          )}
        </label>

        {/* rgNro */}
        <label htmlFor="rgNro" className="flex flex-col w-full col-span-2">
          <span>RG</span>
          <input
            id="rgNro"
            type="text"
            className="rounded-lg"
            placeholder="RG"
            {...register('rgNro', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.rgNro && <span className="text-red-500 text-xs">{errors?.rgNro?.message}</span>}
        </label>

        {/* cpf */}
        <label htmlFor="cpf" className="flex flex-col w-full col-span-2">
          <span>CPF</span>
          <InputMask
            id="cpf"
            type="text"
            mask="999.999.999-99"
            maskChar=" "
            className="rounded-lg"
            placeholder="CPF"
            {...register('cpf', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.cpf && <span className="text-red-500 text-xs">{errors?.cpf?.message}</span>}
        </label>

        {/* rgOrgaoEmissor */}
        <label htmlFor="rgOrgaoEmissor" className="col-span-2 flex flex-col z-auto relative">
          <span>Orgão Emissor</span>
          <Controller
            control={control}
            name={'rgOrgaoEmissor'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <ComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                data={
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  auxTables?.OrgaoEmissor?.map((el: any) => ({
                    id: el.id,
                    desc: el.descricao,
                    isVisible: el.isVisible,
                  })) || []
                }
              />
            )}
          />

          {errors?.rgOrgaoEmissor && (
            <span className="text-red-500 text-xs">{errors?.rgOrgaoEmissor?.message}</span>
          )}
        </label>

        {/* rgUF */}
        <label htmlFor="rgUF" className="col-span-2 flex flex-col z-auto relative">
          <span>UF</span>
          <Controller
            control={control}
            name={'rgUF'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <UfComboboxComp value={field.value as unknown as string} onChange={field.onChange} />
            )}
          />

          {errors?.rgUF && <span className="text-red-500 text-xs">{errors?.rgUF?.message}</span>}
        </label>
      </div>

      {/* linha 3 */}
      <div className="grid grid-cols-12 gap-4">
        {/* nomeMae */}
        <label htmlFor="nomeMae" className="flex flex-col w-full col-span-6">
          <span>Nome da Mãe</span>
          <input
            id="nomeMae"
            type="text"
            className="rounded-lg"
            placeholder="Nome do Mãe"
            {...register('nomeMae', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.nomeMae && (
            <span className="text-red-500 text-xs">{errors?.nomeMae?.message}</span>
          )}
        </label>

        {/* nomePai */}
        <label htmlFor="nomePai" className="flex flex-col w-full col-span-6">
          <span>Nome do Pai</span>
          <input
            id="nomePai"
            type="text"
            className="rounded-lg"
            placeholder="Nome do Pai"
            {...register('nomePai', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.nomePai && (
            <span className="text-red-500 text-xs">{errors?.nomePai?.message}</span>
          )}
        </label>
      </div>
    </div>
  );
}

import classNames from 'classnames';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  icon?: React.ReactNode;
}

export function ButtonComp({ label, ...rest }: Props) {
  return (
    <button
      className={classNames(
        'inline-flex w-full items-center justify-center rounded-xl px-4 py-2 text-base font-medium shadow-sm space-x-3',
        'border border-gray-300',
        'bg-secondary-lite text-primary',
        'hover:bg-secondary-lite/70',
        'focus:outline-none focus:ring-2 focus:ring-secondary/50 focus:ring-offset-2',
        'disabled:bg-slate-400',
        'h-8 sm:w-auto sm:text-sm',
      )}
      {...rest}
    >
      {rest?.icon && rest.icon}
      <span>{label}</span>
    </button>
  );
}

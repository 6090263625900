/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IInteresseEducacional } from './model';

export async function getById(usuarioId: string): Promise<IInteresseEducacional | any> {
  try {
    const { data } = await api.get<AxiosResponse<IInteresseEducacional>>(
      '/interesse_educacional/' + usuarioId,
    );
    return data as unknown as IInteresseEducacional;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IInteresseEducacional[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IInteresseEducacional[]>>(
      '/interesse_educacional',
    );
    return data as unknown as IInteresseEducacional[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(
  usuario: Partial<IInteresseEducacional>,
): Promise<IInteresseEducacional | any> {
  try {
    const { data } = await api.put<AxiosResponse<IInteresseEducacional>>(
      '/interesse_educacional/' + usuario.id,
      {
        ...usuario,
      },
    );
    return (data as unknown as IInteresseEducacional[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: IInteresseEducacional): Promise<IInteresseEducacional | any> {
  try {
    const { data } = await api.post<AxiosResponse<IInteresseEducacional>>(
      '/interesse_educacional/',
      {
        ...usuario,
      },
    );
    return (data as unknown as IInteresseEducacional[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: IInteresseEducacional): Promise<IInteresseEducacional | any> {
  try {
    if (usuario.id) {
      return await update(usuario).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(usuario).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<IInteresseEducacional | any> {
  try {
    return await api
      .delete<AxiosResponse<IInteresseEducacional>>('/interesse_educacional/' + usuarioId)
      .then(() => {
        InfoNotification('Registro excluido!');
      });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

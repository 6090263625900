import { useEffect, useState } from 'react';
import { Button } from 'flowbite-react';

interface Props {
  value: string;
  onChange: (value: string, opt: string) => void;
  option: string;
  allOptions: string;
  options: {
    desc: string;
    value: string;
  }[];
}

export default function FilterTextOptionButtonComp({
  value,
  onChange,
  options,
  option,
  allOptions,
}: Props) {
  const [text, setText] = useState<string>(value);
  const [opt, setOpt] = useState<string>(option);

  useEffect(() => {
    if (value) {
      setText(value);
    }
  }, [value]);

  const action = () => {
    if (text) {
      onChange(text, opt || allOptions);
    }
  };

  return (
    <label className="relative inline-flex w-full mx-2">
      <input
        className="peer h-8 min-w-full rounded-lg bg-slate-150 pr-10 py-2 pl-9 text-xs+ ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900"
        placeholder="Pesquisar"
        type="search"
        value={text}
        minLength={2}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            action();
          }
        }}
      />
      <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 transition-colors duration-200"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M3.316 13.781l.73-.171-.73.171zm0-5.457l.73.171-.73-.171zm15.473 0l.73-.171-.73.171zm0 5.457l.73.171-.73-.171zm-5.008 5.008l-.171-.73.171.73zm-5.457 0l-.171.73.171-.73zm0-15.473l-.171-.73.171.73zm5.457 0l.171-.73-.171.73zM20.47 21.53a.75.75 0 101.06-1.06l-1.06 1.06zM4.046 13.61a11.198 11.198 0 010-5.115l-1.46-.342a12.698 12.698 0 000 5.8l1.46-.343zm14.013-5.115a11.196 11.196 0 010 5.115l1.46.342a12.698 12.698 0 000-5.8l-1.46.343zm-4.45 9.564a11.196 11.196 0 01-5.114 0l-.342 1.46c1.907.448 3.892.448 5.8 0l-.343-1.46zM8.496 4.046a11.198 11.198 0 015.115 0l.342-1.46a12.698 12.698 0 00-5.8 0l.343 1.46zm0 14.013a5.97 5.97 0 01-4.45-4.45l-1.46.343a7.47 7.47 0 005.568 5.568l.342-1.46zm5.457 1.46a7.47 7.47 0 005.568-5.567l-1.46-.342a5.97 5.97 0 01-4.45 4.45l.342 1.46zM13.61 4.046a5.97 5.97 0 014.45 4.45l1.46-.343a7.47 7.47 0 00-5.568-5.567l-.342 1.46zm-5.457-1.46a7.47 7.47 0 00-5.567 5.567l1.46.342a5.97 5.97 0 014.45-4.45l-.343-1.46zm8.652 15.28l3.665 3.664 1.06-1.06-3.665-3.665-1.06 1.06z" />
        </svg>
      </span>
      <span className="cursor-pointer right-1 absolute flex h-full w-[10.5rem] items-center justify-center">
        <select
          className="text-primary font-bold w-[10rem] text-xs outline-none border-none bg-transparent ring-0"
          value={opt}
          onChange={(e) => setOpt(e.target.value)}
        >
          <option value={allOptions}>Todos campos</option>
          {options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.desc}
            </option>
          ))}
        </select>
        <Button outline={false} color={'primary'} size="xs" onClick={action}>
          Ir
        </Button>
      </span>
    </label>
  );
}

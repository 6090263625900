import ReactPaginate from 'react-paginate';

type Props = {
  onPageChange: (selectedPage: { selected: number }) => void;
  pageCount: number;
  page: number;
};

export default function PaginateComp(props: Props) {
  return (
    <ReactPaginate
      className="flex text-xs space-x-2"
      pageClassName="flex flex-col justify-center bg-slate-200 text-slate-700 hover:bg-secondary-lite hover:text-slate-200 rounded-lg"
      activeClassName="flex flex-col justify-center rounded-lg"
      activeLinkClassName="flex flex-col bg-secondary-lite text-primary justify-center rounded-lg"
      pageLinkClassName="px-4 py-1"
      breakLabel="..."
      nextLabel="Próximo"
      nextClassName="flex flex-col justify-center bg-slate-200 text-slate-700 hover:bg-secondary-lite hover:text-slate-200 rounded-lg"
      nextLinkClassName="px-4 py-1"
      onPageChange={props.onPageChange}
      pageRangeDisplayed={5}
      pageCount={props.pageCount}
      forcePage={props.page - 1}
      previousLabel="Anterior"
      previousClassName="flex flex-col justify-center bg-slate-200 text-slate-700 hover:bg-secondary-lite hover:text-slate-200 rounded-lg"
      previousLinkClassName="px-4 py-1"
      renderOnZeroPageCount={() => null}
    />
  );
}

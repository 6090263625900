import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, NoSymbolIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react';
import LoadingComp from '../../../Components/LoadingComp';
import { IMesoRegiao } from './model';
import { getAll } from './service';

interface Props {
  value: number;
  onChange: (value: number) => void;
}

export default function MesoRegiaoComboboxComp({ value, onChange }: Props) {
  const [data, setData] = useState<IMesoRegiao[]>([]);
  const [selected, setSelected] = useState<IMesoRegiao>();
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData([]);
    setLoading(true);
    getAll()
      .then((data) => data && setData(data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (selected) onChange(selected.id);
  }, [onChange, selected]);

  useEffect(() => {
    if (value && data) {
      setSelected(data.find((d) => d.id === value));
    }
  }, [data, value]);

  const filtered =
    query === ''
      ? data
      : data
          .filter((dt) =>
            dt.descricao
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          )
          .filter((d) => d.isVisible);

  if (loading) {
    return (
      <div className="w-full flex justify-start form-input rounded-lg focus:ring-0">
        <LoadingComp />
      </div>
    );
  }

  return (
    <div className="relative w-full z-auto">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative">
          <div className="relative py-1 w-full cursor-default overflow-hidden rounded-lg bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary/20 sm:text-sm">
            <Combobox.Input
              className="w-full form-input rounded-lg pl-3 pr-10 text-sm text-gray-900 focus:ring-0"
              onChange={(event) => setQuery(event.target.value)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              displayValue={(dt: any) => dt?.descricao || ''}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute z-auto mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filtered.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-1 px-4 text-gray-700">
                  Não encontrado.
                </div>
              ) : (
                filtered.map((data) => (
                  <Combobox.Option
                    key={data.id}
                    className={({ active }) =>
                      `relative cursor-default py-2 pl-10 pr-4 ${
                        active && 'bg-primary text-white'
                      } ${
                        data.isVisible
                          ? 'cursor-default'
                          : 'cursor-not-allowed bg-red-50 text-red-400'
                      }`
                    }
                    value={data}
                    disabled={!data.isVisible}
                  >
                    {({ selected, active }) => (
                      <div className="">
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {data.descricao}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-current'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : data.isVisible ? null : (
                          <span
                            className={
                              'absolute inset-y-0 left-0 flex items-center pl-3 text-red-300'
                            }
                          >
                            <NoSymbolIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

import { Dialog } from '@headlessui/react';
import { useState } from 'react';
import { ErrorNotification } from '../../app.notification';
import { ButtonComp } from '../../Components/ButtonComp';
import { ButtonDeleteComp } from '../../Components/ButtonDeleteComp';
import { filiacao } from './service';

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalData: Record<string, any>;
  afterAction: () => void;
}

export default function FormEntidadeClasseModal(props: Props) {
  const [date, setDate] = useState('');

  function isValidDate(dateString: string) {
    const date = new Date(dateString.replace(/-/g, '/'));
    return !isNaN(date.getTime());
  }

  function closeModal() {
    props.setIsOpen(false);
  }

  const handlerAction = async () => {
    if (!date) {
      ErrorNotification('Defina uma data');
      return;
    }

    if (!isValidDate(date)) {
      ErrorNotification('Data invalida');
      return;
    }
    const { id, arg } = props.modalData;

    const args = {
      associado: Number(id),
      tipoEvento: arg,
      dataEvento: date,
    };

    await filiacao(args);
    props.afterAction();
    closeModal();
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">Entidade de Classe</span>
            {/* <button onClick={closeModal} className="hover:text-gray-400">
              <XMarkIcon className="w-5 h-5" />
            </button> */}
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6">
            <p className="text-md font-display font-bold mb-2">
              {props.modalData?.info || 'Não definido'}
            </p>
            <div className="flex space-x-2 items-center mb-4 justify-end">
              <span>Data do Evento</span>
              <input
                id="nascimento"
                type="date"
                className="rounded-lg"
                placeholder="Nascimento"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="flex justify-between">
              <ButtonDeleteComp label="Cancelar" type="button" onClick={closeModal} />
              <ButtonComp label="Confirmar" type="button" onClick={handlerAction} />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

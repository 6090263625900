/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS, getImageAssociadoPath } from '../../app.settings';
import { BreadcrumbsComp } from '../../Components/BreadcrumbsComp';
import { ButtonBackComp } from '../../Components/ButtonBackComp';
import { ButtonComp } from '../../Components/ButtonComp';
import LoadingPageComp from '../../Components/LoadingPageComp';

import { useAssociado } from './context';
import FormAssociadoAnotacoes from './formAnotacoes';
import FormAssociadoContatos from './formContatos';
import FormAssociadoDadosFuncionais from './formDadosFuncionais';
import FormEntidadeClasse from './formEntidadeClasse';
import FormAssociadoGeral from './formGeral';
import FormAssociadoImagem from './formImagem';
import { IAssociado, IFiliacao } from './model';
import { getById } from './service';
import FormEntidadeClasseInsert from './formEntidadeClasseInsert';
import { ErrorNotification } from '../../app.notification';
import ModalConfirmDesfiliar from '../../Components/ModalConfirmDesfiliar';

interface ITabs {
  id: string;
  label: string;
  component: JSX.Element;
}

export default function AssociadoForm() {
  const { loading, save, desfiliar, autoLoad } = useAssociado();
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [render, setRender] = useState(0);
  const [image, setImage] = useState<string | undefined>(undefined);
  const [modalConfirmDesfiliar, setModalConfirmDesfiliar] = useState<boolean>(false);

  const [dataFiliacao, setDataFilicao] = useState<IFiliacao[]>([]);
  const [filiado, setfiliado] = useState<boolean>(false);

  const params = useParams();
  const { id } = params;

  const handlerAfterActionFormEntidadeClasse = async () => {
    if (!id) return;
    const res = await getById(id);
    if (!res) return;
    loadForm(res as IAssociado);
    autoLoad();
    setDataFilicao(res.filiacao);
    setfiliado(res.filiado);
    // getById(id).then((data) => {
    //   if (data) {
    //     setDataFilicao(data.filiacao);
    //     setfiliado(data.filiado);
    //     setRender(render + 1);
    //   }
    // });
  };

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setError,
    setFocus,
  } = useForm<IAssociado>();

  const tabs: ITabs[] = [
    // geral
    {
      id: 'geral',
      label: 'geral',
      component: <FormAssociadoGeral register={register} errors={errors} control={control} />,
    },
    // contato
    {
      id: 'contato',
      label: 'contato',
      component: (
        <FormAssociadoContatos
          register={register}
          errors={errors}
          control={control}
          watch={watch}
        />
      ),
    },
    // dadosfuncionais
    {
      id: 'dadosfuncionais',
      label: 'dados funcionais',
      component: (
        <FormAssociadoDadosFuncionais register={register} errors={errors} control={control} />
      ),
    },
    // entidadeclasse
    {
      id: 'entidadeclasse',
      label: 'entidade de classe',
      component: id ? (
        <FormEntidadeClasse
          list={dataFiliacao}
          filiado={filiado}
          id={id}
          afterAction={handlerAfterActionFormEntidadeClasse}
        />
      ) : (
        <FormEntidadeClasseInsert register={register} errors={errors} setValue={setValue} />
      ),
    },
    // fotografia
    {
      id: 'fotografia',
      label: 'fotografia',
      component: id ? (
        <FormAssociadoImagem register={register} watch={watch} img={image} />
      ) : (
        <div className="flex justify-center items-center h-full">
          <div className="flex flex-col justify-center items-center">
            <div className="text-2xl font-bold text-gray-500">Associado não cadastrado!</div>
            <div className="text-sm text-gray-500">É necessário cadastrar o associado primeiro</div>
          </div>
        </div>
      ),
    },
    // anotacoes
    {
      id: 'anotacoes',
      label: 'anotações',
      component: <FormAssociadoAnotacoes register={register} watch={watch} />,
    },
    // {
    //   id: 'protocolo',
    //   label: 'protocolo',
    //   component: <div>protocolo</div>,
    // },
  ];

  const loadForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (record: Record<string, any>) => {
      if (!record) return;
      const entries = Object.entries(record);
      entries.forEach(([key, value]) => {
        if (!value || value.length === 0) return;
        switch (key) {
          case 'estadoCivil':
          case 'concurso':
          case 'situacaoFuncional':
          case 'vinculoFuncional':
          case 'lotacao':
          case 'grauInstrucao':
          case 'interesseEducacional':
          case 'rgOrgaoEmissor': {
            setValue(key as any, value.id);
            break;
          }
          case 'municipio': {
            setValue(key as any, value.ibge);
            break;
          }
          case 'filiacao': {
            break;
          }

          case 'images': {
            console.log('images', { value });
            setValue(key as any, value);
            setImage(getImageAssociadoPath(value[0]));
            break;
          }

          default:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(key as any, value);
        }
      });
    },
    [setValue],
  );

  useEffect(() => {
    if (id) {
      // setLoading(true);
      getById(id).then((data) => {
        if (data) {
          setDataFilicao(data.filiacao);
          setfiliado(data.filiado);
          loadForm(data);
        }
      });
    }
  }, [id, loadForm]);

  const navigateToList = async (sleep: number) => {
    await new Promise((t) => setTimeout(t, sleep)).then(() => {
      navigate(PATHS.associado);
    });
  };

  const submit = async (data: IAssociado) => {
    if (!data.nome) {
      ErrorNotification('O "NOME" deve ser informado!');
      setTabIndex(0);
      setError('nome', { message: 'Informe o "NOME"' });
      setFocus('nome');
      return;
    }

    if (!data.nascimento) {
      ErrorNotification('A "DATA DE NASCIMENTO" deve ser informada!');
      setTabIndex(0);
      setError('nascimento', { message: 'Informe a "DATA DE NASCIMENTO"' });
      setFocus('nascimento');
      return;
    }

    if (!data.sexo) {
      ErrorNotification('O "SEXO"  deve ser informado!');
      setTabIndex(0);
      setError('sexo', { message: 'Informe o "SEXO"' });
      setFocus('sexo');
      return;
    }

    if (!data.uf) {
      ErrorNotification('A "UF"  deve ser informada!');
      setTabIndex(1);
      setError('uf', { message: 'Informe a "UF"' });
      setFocus('uf');
      return;
    }

    if (!data.municipio) {
      ErrorNotification('A "CIDADE"  deve ser informada!');
      setTabIndex(1);
      setError('municipio', { message: 'Informe a "CIDADE"' });
      setFocus('municipio');
      return;
    }

    if (!data.exercicio) {
      ErrorNotification('O "EXERCÍCIO"  deve ser informado!');
      setTabIndex(2);
      setError('exercicio', { message: 'Informe o "EXERCÍCIO"' });
      setFocus('exercicio');
      return;
    }

    const inserting = location.pathname.includes('inserir');

    delete data.images;
    console.log('🚀 ~ submit ~ data:', data);
    const result = await save(data);

    if (inserting && result) {
      const path = PATHS.associadoEdit(result.id);
      navigate(path, { replace: true, state: { id: result.id } });
      return;
    } else {
      if (!id) return;
      const res = await getById(id);
      if (!res) return;
      loadForm(res as IAssociado);
      setDataFilicao(res.filiacao);
      setfiliado(res.filiado);

      if (res.situacaoFuncional.descricao.toLowerCase() !== 'ativo') {
        setModalConfirmDesfiliar(true);
      }
    }
  };

  // const del = () => {
  // const id = getValues('id');
  // if (id) {
  //   remove(id).then(loadForm);
  //   navigateToList(1000);
  // }
  // };

  return (
    <form onSubmit={handleSubmit(submit)} className="divide-y space-y-4">
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp
          title={watch('id') ? 'Alterar' : 'Inserir'}
          paths={[
            {
              label: 'Associados',
              onClick: () => navigateToList(0),
            },
          ]}
        />
        <div className="flex space-x-2">
          <ButtonBackComp label="Voltar" type="button" onClick={() => navigateToList(0)} />
          {/* {watch('id') && (
            <ButtonDeleteComp confirm={true} label="Excluir" type="button" onClick={del} />
          )} */}
          <ButtonComp label="Salvar" type="submit" />
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingPageComp />
        ) : (
          <div className="w-full space-y-2">
            <Tab.Group selectedIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
              <Tab.List className="flex space-x-1 rounded-xl bg-slate-300 p-1">
                {tabs.map((tab) => (
                  <Tab
                    key={tab.id}
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 px-2 text-xs font-medium leading-5 text-primary',
                        'ring-white ring-opacity-60 ring-offset-2 ring-offset-primary-focus focus:outline-none focus:ring-2',
                        selected
                          ? 'bg-secondary-lite text-primary border border-primary shadow'
                          : 'text-white hover:bg-white/[0.12] hover:text-white',
                      )
                    }
                  >
                    {tab.label.toUpperCase()}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2">
                {tabs.map((tab) => (
                  <Tab.Panel
                    key={tab.id}
                    className={classNames(
                      'rounded-xl bg-white p-3',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    )}
                  >
                    {tab.component}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          </div>
        )}
      </div>
      <ModalConfirmDesfiliar
        isOpen={modalConfirmDesfiliar}
        setIsOpen={setModalConfirmDesfiliar}
        action={async () => {
          if (!id) return;
          await desfiliar(id);
          await new Promise((t) => setTimeout(t, 300));
          await handlerAfterActionFormEntidadeClasse();
        }}
      />
    </form>
  );
}

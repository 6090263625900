/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IContato } from './model';

export async function getById(usuarioId: string): Promise<IContato | any> {
  try {
    const { data } = await api.get<AxiosResponse<IContato>>('/contatos/' + usuarioId);
    return data as unknown as IContato;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IContato[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IContato[]>>('/contatos');
    return data as unknown as IContato[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(usuario: Partial<IContato>): Promise<IContato | any> {
  try {
    const { data } = await api.put<AxiosResponse<IContato>>('/contatos/' + usuario.id, {
      ...usuario,
    });
    return (data as unknown as IContato[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: IContato): Promise<IContato | any> {
  try {
    const { data } = await api.post<AxiosResponse<IContato>>('/contatos/', {
      ...usuario,
    });
    return (data as unknown as IContato[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: IContato): Promise<IContato | any> {
  try {
    if (usuario.id) {
      return await update(usuario).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(usuario).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<IContato | any> {
  try {
    return await api.delete<AxiosResponse<IContato>>('/contatos/' + usuarioId).then(() => {
      InfoNotification('Registro excluido!');
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

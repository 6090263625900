import 'react-data-grid/lib/styles.css';
import DataGrid, { Column } from 'react-data-grid';

import { IAssociado } from './model';
import { Link, useNavigate } from 'react-router-dom';
import { PATHS } from '../../app.settings';
import { EyeIcon } from '@heroicons/react/20/solid';

import { ReactComponent as ImageSvg } from '../../assets/svg/image.svg';
import { ReactComponent as NoImageSvg } from '../../assets/svg/noimage.svg';

interface Props {
  list: IAssociado[];
}

export default function ViewGrid({ list }: Props) {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function formatedBoolean(cellValue: boolean) {
    return <span>{cellValue ? 'Sim' : 'Não'}</span>;
  }

  function formatedDate(cellValue: string | Date) {
    if (!cellValue) return 'Não Definido';
    return (
      <span>{new Date((cellValue as string).replace(/-/g, '/')).toLocaleDateString('pt-Br')}</span>
    );
  }
  function formatedConcat([...cellValue]: string[]) {
    if (cellValue.length === 0) return 'Não Definido';
    return cellValue.join('/');
  }

  const columns: Column<IAssociado>[] = [
    { key: 'nome', name: 'Nome', frozen: true, cellClass: 'cursor-pointer hover:font-bold' },
    {
      key: 'images',
      name: 'Foto',
      width: 3,
      formatter: ({ row }) => (
        <div>
          {row?.images && row.images.length > 0 ? (
            <ImageSvg className="w-6 h-6" />
          ) : (
            <NoImageSvg className="w-5 h-5" />
          )}
        </div>
      ),
    },
    {
      key: 'filiado',
      name: 'Filiado',
      width: 5,
      formatter: ({ row }) => formatedBoolean(row.filiado),
    },
    {
      key: 'sindicatoData',
      name: 'Filiado em',
      formatter: ({ row }) => formatedDate(row.sindicatoData),
    },
    { key: 'nascimento', name: 'Nascimento', formatter: ({ row }) => formatedDate(row.nascimento) },
    { key: 'sexo', name: 'Sexo', width: 5 },
    { key: 'naturalidade', name: 'Naturalidade' },
    { key: 'estadoCivil', name: 'Est. Civil', formatter: ({ row }) => row.estadoCivil?.descricao },
    {
      key: 'rgNro',
      name: 'RG/Emissor/UF',
      formatter: ({ row }) => {
        const array: string[] = [];
        if (row.rgNro) array.push(row.rgNro);
        if (row.rgOrgaoEmissor?.descricao) array.push(row.rgOrgaoEmissor?.descricao);
        if (row.rgUF) array.push(row.rgUF);
        return formatedConcat(array);
      },
    },
    { key: 'cpf', name: 'CPF' },
    { key: 'nomeMae', name: 'Pai' },
    { key: 'nomePai', name: 'Mãe' },
    { key: 'emailInstitucional', name: 'Email Inst.' },
    { key: 'email', name: 'Email Pessoal' },
    {
      key: 'endereco',
      name: 'Endereço/Comp',
      formatter: ({ row }) => {
        const array: string[] = [];
        if (row.endereco) array.push(row.endereco);
        if (row.complemento) array.push(row.complemento);
        return formatedConcat(array);
      },
    },
    { key: 'bairro', name: 'Bairro' },

    {
      key: 'cidade',
      name: 'Cidade/UF',
      formatter: ({ row }) => {
        const array: string[] = [];
        if (row.cidade) array.push(row.cidade);
        if (row.uf) array.push(row.uf);
        return formatedConcat(array);
      },
    },
    { key: 'cep', name: 'CEP' },
    { key: 'cel1', name: 'Celular' },
    { key: 'fixo', name: 'Fixo' },
    {
      key: 'matricula',
      name: 'Matrícula',
      formatter: ({ row }) => {
        const array: string[] = [];
        if (row.matricula) array.push(row.matricula);
        if (row.matriculaDv) array.push(row.matriculaDv);
        return formatedConcat(array);
      },
    },
    {
      key: 'lotacao',
      name: 'Lotação',
      formatter: ({ row }) => {
        const array: string[] = [];
        if (row?.lotacao?.local) array.push(row.lotacao.local);
        return formatedConcat(array);
      },
    },
    {
      key: 'concurso',
      name: 'Concurso',
      formatter: ({ row }) => row?.concurso?.descricao,
    },
    { key: 'exercicio', name: 'Exercício' },
    {
      key: 'situacaoFuncional',
      name: 'Sit. Funcional',
      formatter: ({ row }) => row?.situacaoFuncional?.descricao,
    },
    {
      key: 'vinculoFuncional',
      name: 'Vinc. Funcional',
      formatter: ({ row }) => row?.vinculoFuncional?.descricao,
    },
    { key: 'cargo', name: 'Cargo' },
    { key: 'funcao', name: 'Funcao' },
    {
      key: 'grauInstrucao',
      name: 'Grau Instrução',
      formatter: ({ row }) => row?.grauInstrucao?.descricao,
    },
    { key: 'instituicao', name: 'Instituíção' },
    { key: 'cursoGraduacao', name: 'Curso Graduação' },
    { key: 'especialidade', name: 'Especialidade' },
    {
      key: 'interesseEducacional',
      name: 'Interesse Educacional',
      formatter: ({ row }) => row?.interesseEducacional?.descricao,
    },
    {
      key: 'temDivida',
      name: 'Tem dívida',
      width: 120,
      formatter: ({ row }) => formatedBoolean(row.temDivida),
    },

    // acoes
    {
      key: 'id',
      name: 'Ações',
      formatter: ({ row }) => {
        const id = row.id;
        return (
          <Link
            className="hover:underline hover:font-bold flex items-center justify-center h-full"
            to={PATHS.associadoEdit(String(id))}
          >
            <EyeIcon className="h-5 w-5" />
          </Link>
        );
      },
    },
  ];

  function EmptyRowsRenderer() {
    return <div style={{ textAlign: 'left', gridColumn: '1/-1' }}>Sem dados</div>;
  }

  const rows = list;

  return (
    <DataGrid
      columns={columns}
      className="h-full shadow-md rounded rdg-light"
      rows={rows}
      renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
      onCellDoubleClick={({ column, row }, event) => {
        if (column.key === 'nome') {
          event.preventGridDefault();
          navigate(PATHS.associadoEdit(row.id.toString()));
        }
      }}
    />
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAuth } from '../../Auth/Context';
import { BreadcrumbsComp } from '../../Components/BreadcrumbsComp';
import { ButtonComp } from '../../Components/ButtonComp';

import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.br';
import classNames from 'classnames';
import { IUsuario } from '../Config/Usuario/model';
import { save } from '../Config/Usuario/service';
import ModalUpdatePassword from './ModalUpdatePassword';

export function Profile() {
  const { currentUser, setCurrentUser, updatePassword } = useAuth();
  const {
    register,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUsuario>();

  const loadForm = useCallback(
    (user: Record<string, any>) => {
      const entries = Object.entries(user);
      entries.forEach(([key, value]) => {
        switch (key) {
          default:
            setValue(key as any, value);
        }
      });
    },
    [setValue],
  );

  useEffect(() => {
    if (currentUser) {
      loadForm(currentUser);
    }
  }, [currentUser, loadForm]);

  const submit = (data: IUsuario) => {
    save(data).then((data: IUsuario) => {
      setCurrentUser(data);
      loadForm(data);
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="flex justify-between py-3">
        <BreadcrumbsComp title="Meu Perfil" paths={[]} />
        <ButtonComp label="Alterar" type="submit" />
      </div>
      <div className="max-w-lg my-8 space-y-2">
        <label htmlFor="nome" className="flex flex-col">
          <span>Nome</span>
          <input
            id="nome"
            type="text"
            className="rounded-lg"
            placeholder="Nome"
            {...register('nome', {
              required: {
                value: true,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.nome && <span className="text-red-500 text-xs">{errors?.nome?.message}</span>}
        </label>
        <label htmlFor="email" className="flex flex-col">
          <span>Email</span>
          <input
            id="email"
            type="email"
            className="rounded-lg"
            placeholder="Email"
            {...register('email', {
              required: {
                value: true,
                message: '(*) Campo requerido',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Email inválido',
              },
            })}
          />
          {errors?.email && <span className="text-red-500 text-xs">{errors?.email?.message}</span>}
        </label>
        <label htmlFor="telefone" className="flex flex-col">
          <span>Telefone</span>
          <Controller
            control={control}
            name={'telefone'}
            rules={{
              required: {
                value: true,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <Cleave
                {...field}
                options={{ phone: true, phoneRegionCode: 'br' }}
                className={classNames('form-input peer w-full rounded-lg')}
              />
            )}
          />

          {/* <Cleave
            options={{ phone: true, phoneRegionCode: 'br' }}
            className={classNames('form-input peer w-full rounded-lg')}
            {...register('telefone', {
              required: {
                value: true,
                message: '(*) Campo requerido',
              },
            })}
          /> */}
          {/* <input
            id="telefone"
            type="text"
            className="rounded-lg"
            placeholder="Telefone"
            {...register('telefone', {
              required: {
                value: true,
                message: '(*) Campo requerido',
              },
            })}
          /> */}
          {errors?.telefone && (
            <span className="text-red-500 text-xs">{errors?.telefone?.message}</span>
          )}
        </label>
        <ModalUpdatePassword action={updatePassword} />
      </div>
    </form>
  );
}

/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ErrorNotification, InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IUsuario } from './model';

export async function getById(usuarioId: string): Promise<IUsuario | any> {
  try {
    const { data } = await api.get('/auth/usuarios/' + usuarioId);
    if (!data[0]) {
      InfoNotification('Usuário não localizado');
      return;
    }
    return data[0] as unknown as IUsuario;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IUsuario[] | undefined> {
  try {
    const { data } = await api.get('/auth/usuarios');
    return data.usuarios as unknown as IUsuario[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(usuario: Partial<IUsuario>): Promise<IUsuario | any> {
  usuario.updated_at = usuario.updatedAt;
  try {
    let url = '';
    let body = {};
    if (usuario.perfil === 'ADMIN') {
      url = '/auth/usuarios/admin/' + usuario.id;
      body = {
        usuario: usuario,
      };
    } else {
      url = '/auth/usuarios/' + usuario.id;
      body = {
        ...usuario,
      };
    }
    const { data } = await api.put<AxiosResponse<IUsuario>>(url, body);
    if (!(data as any)) {
      ErrorNotification('Usuário não localizado');
      return;
    }
    InfoNotification('Registro alterado!');
    return (data as unknown as IUsuario[]);
  } catch (error: any) {

    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: IUsuario): Promise<IUsuario | any> {
  try {
    let url = '';
    if (usuario.perfil === 'ADMIN') {
      url = '/auth/usuarios/admin';
    } else {
      url = '/auth/usuarios';
    }
    const { data } = await api.post<AxiosResponse<IUsuario>>(url, {
      ...usuario,
    });
    if (!(data as any)[0]) {
      ErrorNotification('Usuário não inserido');
      return;
    }
    InfoNotification('Registro alterado!');
    return (data as unknown as IUsuario[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: IUsuario): Promise<IUsuario | any> {
  try {
    if (usuario.id) {
      // eslint-disable-next-line camelcase
      // usuario.updated_at = usuario.updatedAt;
      return await update(usuario);
    } else {
      return await insert(usuario);
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export interface UpdatePassword {
  id: string;
  email: string;
  senha: string;
  perfil: string;
  updatedAt?: string;

}
export function updPassword(update: UpdatePassword) {
  try {
    let url = '';
    if (update.perfil === 'ADMIN') {
      url = '/auth/usuarios/admin/alterarsenha/' + update.id;
    } else {
      url = '/auth/usuarios/alterarsenha/' + update.id;
    }
    // const url = '/auth/usuarios/admin/alterarsenha/' + update.id;
    return api.put<AxiosResponse<IUsuario>>(url, {
      usuario: update,
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<IUsuario | any> {
  try {
    return await api.delete<AxiosResponse<IUsuario>>('/auth/usuarios/' + usuarioId).then(() => {
      InfoNotification('Registro excluido!');
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

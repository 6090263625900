import { useState, useEffect, useRef } from 'react';
import { Checkbox, Label } from 'flowbite-react';
import classNames from 'classnames';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (record: Record<string, any>) => void;
  dataKey: string;
  dataDesc: string;
  clearFilter?: number;
}

const CLASS_INPUT = 'rounded-lg text-xs px-2 py-1 disabled:text-slate-500';

export default function CheckFilterPeriod({ action, dataKey, dataDesc, clearFilter }: Props) {
  const [active, setActive] = useState(false);
  const [dtIni, setDtIni] = useState<string>('');
  const [dtEnd, setDtEnd] = useState<string>('');

  useEffect(() => {
    if (active && dtIni && dtEnd) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = new Object();
      obj[dataKey] = [dtIni, dtEnd];
      action({ ...obj });
    } else {
      action({});
    }
  }, [action, active, dataKey, dtEnd, dtIni]);

  const lastCount = useRef(0);

  useEffect(() => {
    if (!active) return;
    if (!clearFilter) return;
    const current = lastCount.current;
    if (clearFilter !== current) {
      setActive(false);
      lastCount.current = clearFilter;
    }
  }, [active, clearFilter]);

  useEffect(() => {
    if (!clearFilter) return;
    lastCount.current = clearFilter;
  }, [clearFilter]);

  return (
    <div
      className={classNames(
        'flex flex-col space-y-2',
        'p-2 rounded-lg',
        active ? 'bg-slate-400 ' : 'bg-slate-200',
        'text-slate-900',
        active ? 'font-bold' : '',
      )}
    >
      <div className="flex items-center gap-2">
        <Checkbox
          id="check"
          defaultChecked={false}
          checked={active}
          onChange={() => setActive(!active)}
        />
        <Label htmlFor="check" className={classNames(active === false && 'text-slate-500')}>
          {dataDesc}
        </Label>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="w-full space-y-2 text-xs">
          <div className="flex justify-between">
            <span>De</span>
            <input
              className={CLASS_INPUT}
              type="date"
              value={dtIni}
              onChange={(e) => setDtIni(e.target.value)}
              disabled={!active}
            />
          </div>
          <div className="flex justify-between">
            <span>Até</span>
            <input
              className={CLASS_INPUT}
              type="date"
              value={dtEnd}
              onChange={(e) => setDtEnd(e.target.value)}
              disabled={!active}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { ISituacaoFuncional } from './model';

export async function getById(usuarioId: string): Promise<ISituacaoFuncional | any> {
  try {
    const { data } = await api.get<AxiosResponse<ISituacaoFuncional>>(
      '/situacao_funcional/' + usuarioId,
    );
    return data as unknown as ISituacaoFuncional;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<ISituacaoFuncional[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<ISituacaoFuncional[]>>('/situacao_funcional');
    return data as unknown as ISituacaoFuncional[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(
  usuario: Partial<ISituacaoFuncional>,
): Promise<ISituacaoFuncional | any> {
  try {
    const { data } = await api.put<AxiosResponse<ISituacaoFuncional>>(
      '/situacao_funcional/' + usuario.id,
      {
        ...usuario,
      },
    );
    return (data as unknown as ISituacaoFuncional[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: ISituacaoFuncional): Promise<ISituacaoFuncional | any> {
  try {
    const { data } = await api.post<AxiosResponse<ISituacaoFuncional>>('/situacao_funcional/', {
      ...usuario,
    });
    return (data as unknown as ISituacaoFuncional[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: ISituacaoFuncional): Promise<ISituacaoFuncional | any> {
  try {
    if (usuario.id) {
      return await update(usuario).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(usuario).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<ISituacaoFuncional | any> {
  try {
    return await api
      .delete<AxiosResponse<ISituacaoFuncional>>('/situacao_funcional/' + usuarioId)
      .then(() => {
        InfoNotification('Registro excluido!');
      });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IComarca } from './model';

export async function getById(regId: string): Promise<IComarca | any> {
  try {
    const { data } = await api.get<AxiosResponse<IComarca>>('/municipio/' + regId);
    return data as unknown as IComarca;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IComarca[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IComarca[]>>('/municipio');
    return data as unknown as IComarca[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function getByUF(uf: string): Promise<IComarca[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IComarca[]>>('/municipio/uf/' + uf);
    return data as unknown as IComarca[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(reg: Partial<IComarca>): Promise<IComarca | any> {
  try {
    const { data } = await api.put<AxiosResponse<IComarca>>('/municipio/' + reg.ibge, {
      ...reg,
    });
    return (data as unknown as IComarca[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(reg: IComarca): Promise<IComarca | any> {
  try {
    const { data } = await api.post<AxiosResponse<IComarca>>('/municipio/', {
      ...reg,
    });
    return (data as unknown as IComarca[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(reg: IComarca): Promise<IComarca | any> {
  try {
    if (reg.ibge) {
      return await update(reg).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(reg).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(regId: string): Promise<IComarca | any> {
  try {
    return await api.delete<AxiosResponse<IComarca>>('/municipio/' + regId).then(() => {
      InfoNotification('Registro excluido!');
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

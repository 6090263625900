import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ButtonComp } from './ButtonComp';
import { ButtonDeleteComp } from './ButtonDeleteComp';

interface ModalConfirmationProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  question: string;
  answer: () => void;
}

export default function ModalConfirmation(props: ModalConfirmationProps) {
  function closeModal() {
    props.setIsOpen(false);
  }

  const confirm = () => {
    props.answer();
    closeModal();
  };

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
      open={props.isOpen}
    >
      <div className="min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <Dialog.Title
            as="h3"
            className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
          >
            <span className="text-lg font-bold leading-6 font-display">{props.title}</span>
            <button onClick={closeModal} className="hover:text-gray-400">
              <XMarkIcon className="w-5 h-5" />
            </button>
          </Dialog.Title>
          <div className="flex flex-col font-display py-4 px-6">
            <p className="text-md font-display font-bold mb-8">{props.question}</p>
            <div className="flex justify-between">
              <ButtonDeleteComp label="Não" type="button" onClick={closeModal} />
              <ButtonComp label="Sim" type="button" onClick={confirm} />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

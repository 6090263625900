/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IVinculo } from './model';

export async function getById(usuarioId: string): Promise<IVinculo | any> {
  try {
    const { data } = await api.get<AxiosResponse<IVinculo>>('/vinculo/' + usuarioId);
    return data as unknown as IVinculo;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IVinculo[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IVinculo[]>>('/vinculo');
    return data as unknown as IVinculo[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(usuario: Partial<IVinculo>): Promise<IVinculo | any> {
  try {
    const { data } = await api.put<AxiosResponse<IVinculo>>('/vinculo/' + usuario.id, {
      ...usuario,
    });
    return (data as unknown as IVinculo[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: IVinculo): Promise<IVinculo | any> {
  try {
    const { data } = await api.post<AxiosResponse<IVinculo>>('/vinculo/', {
      ...usuario,
    });
    return (data as unknown as IVinculo[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: IVinculo): Promise<IVinculo | any> {
  try {
    if (usuario.id) {
      return await update(usuario).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(usuario).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<IVinculo | any> {
  try {
    return await api.delete<AxiosResponse<IVinculo>>('/vinculo/' + usuarioId).then(() => {
      InfoNotification('Registro excluido!');
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

import { useState, useEffect, useRef, useCallback } from 'react';
import { Checkbox, Label } from 'flowbite-react';
import classNames from 'classnames';
import ComboboxComp from '../../../Components/ComboboxComp';
import { useAssociado } from '../context';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (record: Record<string, any>) => void;
  dataKey: string;
  dataDesc: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  auxTable: string;
  clearFilter?: number;
}

export default function CheckFilterGeneric({
  action,
  dataKey,
  dataDesc,
  auxTable,
  clearFilter,
}: Props) {
  const { updateAuxTables, auxTables } = useAssociado();
  const [active, setActive] = useState(false);
  const [resp, setResp] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [render, setRender] = useState(0);

  const loadItems = useCallback(async () => {
    if (items.length === 0) {
      setItems([]);
      const data = await updateAuxTables(auxTable);
      const dataItems =
        data.map((el: any) => ({
          id: el.id,
          desc: el?.descricao ?? el?.local ?? el?.nome ?? '',
          isVisible: el.isVisible ?? true,
        })) || [];
      setItems(dataItems);
      setRender((prev) => prev + 1);
    }
  }, [auxTable, items.length, updateAuxTables]);

  useEffect(() => {
    if (active) {
      loadItems();
    } else {
      setItems([]);
    }
  }, [active, auxTable, auxTables, loadItems, updateAuxTables]);

  useEffect(() => {
    if (active && resp) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = new Object();
      obj[dataKey] = `'${resp}'`;
      action({ ...obj });
    } else {
      action({});
    }
  }, [action, active, dataKey, resp]);

  const lastCount = useRef(0);

  useEffect(() => {
    if (!active) return;
    if (!clearFilter) return;
    const current = lastCount.current;
    if (clearFilter !== current) {
      setActive(false);
      lastCount.current = clearFilter;
    }
  }, [active, clearFilter]);

  useEffect(() => {
    if (!clearFilter) return;
    lastCount.current = clearFilter;
  }, [clearFilter]);

  return (
    <div
      className={classNames(
        'flex flex-col space-y-2',
        'p-2 rounded-lg',
        active ? 'bg-slate-400 ' : 'bg-slate-200',
        'text-slate-900',
        active ? 'font-bold' : '',
      )}
    >
      <div className="flex items-center gap-2">
        <Checkbox
          id="check"
          defaultChecked={false}
          checked={active}
          onChange={() => setActive(!active)}
        />
        <Label htmlFor="check" className={classNames(active === false && 'text-slate-500')}>
          {dataDesc}
        </Label>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="w-full">
          <ComboboxComp
            data={items}
            value={resp}
            onChange={setResp}
            min={true}
            disabled={!active}
          />
        </div>
      </div>
    </div>
  );
}

import { useRef, useState } from 'react';
import { Button, Table } from 'flowbite-react';
import FormEntidadeClasseModal from './formEntidadeClasseModal';
import { IFiliacao } from './model';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useAssociado } from './context';

interface Props {
  id: string | undefined;
  list: IFiliacao[];
  filiado: boolean;
  afterAction: () => void;
}

export default function FormEntidadeClasse({ id, list, filiado, afterAction }: Props) {
  const { autoLoad } = useAssociado();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const modalInfo = useRef<Record<string, any>>({});

  const renderTipoEvento = (tipo: string): string => {
    if (tipo === 'A') {
      return 'Admissão';
    } else {
      return 'Exclusão';
    }
  };

  const handlerAdmissaoAction = () => {
    modalInfo.current = {
      info: 'Admissão de associado',
      arg: 'A',
      id,
    };
    setModalOpen(true);
  };

  const handlerExclusaoAction = () => {
    modalInfo.current = {
      info: 'Exclusão de associado',
      arg: 'E',
      id,
    };
    setModalOpen(true);
  };

  return (
    <>
      <div className="flex justify-between space-x-2 py-2 bg-slate-100 my-2 px-1 rounded-lg">
        <span className={classNames(filiado ? 'text-green-500' : 'text-red-500')}>
          {filiado ? 'Filiado' : 'Não Filiado'}
        </span>

        {filiado ? (
          <Button color="failure" size="xs" onClick={handlerExclusaoAction}>
            <div className="mr-3">
              <XCircleIcon className="w-4 h-4" />
            </div>
            Exclusão
          </Button>
        ) : (
          <Button color="success" size="xs" onClick={handlerAdmissaoAction}>
            <div className="mr-3">
              <PlusCircleIcon className="w-4 h-4" />
            </div>
            Admissão
          </Button>
        )}
      </div>
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell>Tipo Evento</Table.HeadCell>
          <Table.HeadCell>Data Evento</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {list.map((li, i) => (
            <Table.Row key={i} className="bg-white dark:border-gray-700 dark:bg-gray-800">
              <Table.Cell>{renderTipoEvento(li.tipoEvento)}</Table.Cell>
              <Table.Cell>
                {new Date(li.dataEvento.replace(/-/g, '/')).toLocaleDateString('pt-BR')}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <FormEntidadeClasseModal
        isOpen={id !== undefined && modalOpen}
        setIsOpen={setModalOpen}
        modalData={modalInfo.current}
        afterAction={() => {
          afterAction();
          autoLoad();
        }}
      />
    </>
  );
}

import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../app.settings';
import { BreadcrumbsComp } from '../../../Components/BreadcrumbsComp';
import FilterTextComp from '../../../Components/FilterTextComp';
import LoadingPageComp from '../../../Components/LoadingPageComp';
import UfComboboxComp from '../../../Components/UfComboboxComp';
import { useComarca } from './context';
import { Table } from 'flowbite-react';
import NoResultsPNG from '../../../assets/png/no-results.png';
import Highlighter from 'react-highlight-words';

export default function ComarcaList() {
  const navigate = useNavigate();
  const { loading, list, setUf, filter, setFilter, setCurrent } = useComarca();

  return (
    <>
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp title="Municípios" paths={[]} />
        <div className="flex space-x-2 items-center">
          <span>UF:</span>
          {/* fixar o combo sempre para Ceará */}
          <div className="max-w-xs">
            <UfComboboxComp value={'CE'} onChange={() => setUf('CE')} disabled={true} min={true} />
          </div>
          <FilterTextComp value={filter || ''} onChange={setFilter} />
          {/* <ButtonComp label="Inserir" type="button" onClick={() => navigate(PATHS.comarcaInsert)} /> */}
        </div>
      </div>
      <div className="flex items-center justify-end pb-3">
        {/* <FilterTextComp value={filter} onChange={setFilter} /> */}
      </div>
      <div className="hidden md:block">
        {loading ? (
          <LoadingPageComp />
        ) : (
          <Table hoverable={true}>
            {list.length === 0 ? (
              <div className="w-full">
                <img className="mx-auto grayscale-0 opacity-50" src={NoResultsPNG} alt="" />
              </div>
            ) : (
              <>
                <Table.Head>
                  <Table.HeadCell className="grow">Nome</Table.HeadCell>
                  <Table.HeadCell className="grow">Coordenadoria</Table.HeadCell>
                  <Table.HeadCell className="grow">Meso-Região</Table.HeadCell>
                  <Table.HeadCell className="grow">Micro-Região</Table.HeadCell>
                </Table.Head>

                <Table.Body className="divide-y">
                  {list.map((el) => (
                    <Table.Row
                      key={el.ibge}
                      className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer"
                      onClick={async () => {
                        setCurrent(el);
                        await new Promise((t) => setTimeout(t, 300));
                        navigate(PATHS.comarcaEdit(el.ibge.toString()));
                      }}
                    >
                      <Table.Cell className="font-medium text-gray-900 dark:text-white">
                        <span>
                          {filter ? (
                            <Highlighter searchWords={[filter]} textToHighlight={el.nome} />
                          ) : (
                            <span>{el.nome}</span>
                          )}
                        </span>
                      </Table.Cell>
                      <Table.Cell className="font-medium text-gray-900 dark:text-white">
                        {filter ? (
                          <Highlighter
                            searchWords={[filter]}
                            textToHighlight={el?.coordenadoria?.descricao}
                          />
                        ) : (
                          <span>{el?.coordenadoria?.descricao}</span>
                        )}
                      </Table.Cell>
                      <Table.Cell className="font-medium text-gray-900 dark:text-white">
                        {filter ? (
                          <Highlighter
                            searchWords={[filter]}
                            textToHighlight={el?.mesoRegiao?.descricao}
                          />
                        ) : (
                          <span>{el?.mesoRegiao?.descricao}</span>
                        )}
                      </Table.Cell>
                      <Table.Cell className="font-medium text-gray-900 dark:text-white">
                        {filter ? (
                          <Highlighter
                            searchWords={[filter]}
                            textToHighlight={el?.microRegiao?.descricao}
                          />
                        ) : (
                          <span>{el?.microRegiao?.descricao}</span>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </>
            )}
          </Table>
        )}
      </div>
    </>
  );
}

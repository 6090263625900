import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { LOCAL_STORAGE_PREFIX } from '../../app.settings';
import { getAll as getAllConcurso } from '../Cad/Concurso/service';
import { getAll as getAllCoordenadoria } from '../Cad/Coordenadoria/service';
import { getAll as getAllEstadoCivil } from '../Cad/EstadoCivil/service';
import { getAll as getAllGrauInstrucao } from '../Cad/GrauInstrucao/service';
import { getAll as getAllInteresseEducacional } from '../Cad/InteresseEducacional/service';
import { getAll as getAllLotacao } from '../Cad/Lotacao/service';
import { getAll as getAllMesoRegiao } from '../Cad/MesoRegiao/service';
import { getAll as getAllMicroRegiao } from '../Cad/MicroRegiao/service';
import { getAll as getAllOrgaoEmissor } from '../Cad/OrgaoEmissor/service';
import { getAll as getAllSituacaoFuncional } from '../Cad/SituacaoFuncional/service';
import { getAll as getAllVinculo } from '../Cad/Vinculo/service';
import { IAssociado } from './model';
import { find, save as saveReg, upload, filiacao } from './service';
import { useLocalStorage } from '../../Hooks/useLocalStorage';
import { ErrorNotification, SuccessNotification } from '../../app.notification';
import { IInfo } from '../../app.models';

interface IContext {
  loading: boolean;
  setLoading: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilter: (args: Record<string, any>) => void;
  setSort: (sort: number) => void;
  setTake: (take: number) => void;
  sort: number;
  setSkip: (take: number) => void;
  list: IAssociado[];
  listExcel: IAssociado[];
  loadExcel: () => void;
  info: IInfo;
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  save: (data: Partial<IAssociado>) => Promise<any>;
  auxTables: Record<string, unknown[]>;
  viewMode: string;
  setViewMode: (mode: string) => void;
  autoLoad: () => void;
  uplodImg: (file: File, id: number, dataUrl: any, cb: () => void) => void;
  updateAuxTables: (table: string) => Promise<any[]>;
  loadTables: () => void;
  desfiliar: (id: string) => Promise<void>;
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<IAssociado[]>([]);
  const [listExcel, setListExcel] = useState<IAssociado[]>([]);
  const [info, setInfo] = useState({} as IInfo);
  const [showFilters, setShowFilters] = useLocalStorage<boolean>('associado-filtro-open', false);
  // const [current, setCurrent] = useState<IAssociado>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filter, _setFilter] = useLocalStorage<Record<string, any>>('associado-filtro', {
    filiado: true,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [auxTables, setAuxTable] = useState<Record<string, any[]>>({});
  const [take, setTake] = useLocalStorage<number>('take-paginate-associado', 10);
  const [sort, setSort] = useLocalStorage<number>('sort-paginate-associado', 1);
  const [skip, setSkip] = useState<number>(0);
  const [viewMode, _setViewMode] = useState<string>(
    localStorage.getItem(LOCAL_STORAGE_PREFIX + 'associadoViewMode') || 'card',
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const load = useCallback(
    (filter: Record<string, any>) => {
      setLoading(true);
      // if (Object.keys(filter).length === 0) {
      //   setList([]);
      //   setInfo({} as IInfo);
      //   setLoading(false);
      //   return;
      // }
      find({ body: filter, sort: sort, skip, take }).then((data) => {
        data && setList(data.associados);
        data && setInfo(data.info);
        setLoading(false);
      });
    },
    [sort, skip, take],
  );

  const loadExcel = useCallback(() => {
    if (Object.keys(filter).length === 0) {
      setListExcel([]);
      return;
    }
    find({ body: filter, sort: sort, take: -1 }).then((data) => {
      data && setListExcel(data.associados);
    });
  }, [filter, sort]);

  const autoLoad = () => {
    setLoading(false);
    if (filter && Object.keys(filter).length > 0) {
      find({ body: filter, sort: sort }).then((data) => {
        setList(data?.associados ?? []);
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setFilter = useCallback(
    (newFilter: Record<string, any>) => {
      _setFilter(newFilter);
      load(newFilter);
      // setShowFilters(false);
    },
    [_setFilter, load],
  );

  const setViewMode = (mode: string) => {
    localStorage.setItem(LOCAL_STORAGE_PREFIX + 'associadoViewMode', mode);
    _setViewMode(mode);
  };

  const loadTables = useCallback(() => {
    getAllEstadoCivil().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, EstadoCivil: data })),
    );

    // getAllComarcas().then(
    //   (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Comarcas: data })),
    // );

    getAllConcurso().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Concurso: data })),
    );

    getAllCoordenadoria().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Coordenadoria: data })),
    );

    getAllGrauInstrucao().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, GrauInstrucao: data })),
    );

    getAllInteresseEducacional().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, InteresseEducacional: data })),
    );

    getAllLotacao().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Lotacao: data })),
    );

    getAllMesoRegiao().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, MesoRegiao: data })),
    );

    getAllMicroRegiao().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, MicroRegiao: data })),
    );

    getAllOrgaoEmissor().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, OrgaoEmissor: data })),
    );

    getAllSituacaoFuncional().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, SituacaoFuncional: data })),
    );

    getAllVinculo().then(
      (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Vinculo: data })),
    );
  }, []);

  useEffect(() => {
    loadTables();
  }, [loadTables]);

  async function updateAuxTables(table: string): Promise<any[]> {
    switch (table) {
      case 'EstadoCivil':
        await getAllEstadoCivil().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, EstadoCivil: data })),
        );
        break;
      case 'Concurso':
        await getAllConcurso().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Concurso: data })),
        );
        break;
      case 'Coordenadoria':
        await getAllCoordenadoria().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Coordenadoria: data })),
        );
        break;
      case 'GrauInstrucao':
        await getAllGrauInstrucao().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, GrauInstrucao: data })),
        );
        break;
      case 'InteresseEducacional':
        await getAllInteresseEducacional().then(
          (data) =>
            data && setAuxTable((prevArray) => ({ ...prevArray, InteresseEducacional: data })),
        );
        break;
      case 'Lotacao':
        await getAllLotacao().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Lotacao: data })),
        );
        break;
      case 'MesoRegiao':
        await getAllMesoRegiao().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, MesoRegiao: data })),
        );
        break;
      case 'MicroRegiao':
        await getAllMicroRegiao().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, MicroRegiao: data })),
        );
        break;
      case 'OrgaoEmissor':
        await getAllOrgaoEmissor().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, OrgaoEmissor: data })),
        );
        break;
      case 'SituacaoFuncional':
        await getAllSituacaoFuncional().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, SituacaoFuncional: data })),
        );
        break;
      case 'Vinculo':
        await getAllVinculo().then(
          (data) => data && setAuxTable((prevArray) => ({ ...prevArray, Vinculo: data })),
        );
        break;
    }

    return auxTables[table];
  }

  async function save(data: Partial<IAssociado>) {
    const ret = await saveReg(data);
    load(filter);
    return ret;
  }

  async function desfiliar(id: string) {
    // lançar entidade de classe
    const args = {
      associado: Number(id),
      tipoEvento: 'E',
      dataEvento: new Date().toISOString().split('T')[0],
    };

    await filiacao(args);
    autoLoad();
    SuccessNotification('Associado desfiliado com sucesso');
  }

  function blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return theBlob as File;
  }

  const uplodImg = async (file: File, id: number, dataUrl: any, cb: () => void) => {
    const result = await fetch(dataUrl);
    const blob = await result.blob();

    await upload(String(id), blobToFile(blob, 'upload-associado'), '1024', '1024', (progress) => {
      // console.log('🚀 ~ file: formImagem.tsx:72 ~ uplodImg ~ progress', progress * 100);
    })
      .then(() => load(filter))
      .catch((e) => {
        console.log('🚀 ~ Upload Error', e);
        ErrorNotification('Erro ao enviar imagem');
        // window.location.reload();
      })
      .finally(() => {
        SuccessNotification('Imagem enviada com sucesso');
        cb();
        autoLoad();
      });
  };

  useEffect(() => {
    load(filter);
  }, [filter, load]);

  return (
    <Context.Provider
      value={{
        loading,
        setLoading,
        list,
        listExcel,
        loadExcel,
        info,
        // current,
        // setCurrent,
        sort,
        setSkip,
        setFilter,
        setSort,
        setTake,
        save,
        auxTables,
        showFilters,
        setShowFilters,
        viewMode,
        setViewMode,
        autoLoad,
        uplodImg,
        updateAuxTables,
        loadTables,
        desfiliar,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useAssociado = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useAssociado, Provider as AssociadoProvider };

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IGrauInstrucao } from './model';

export async function getById(usuarioId: string): Promise<IGrauInstrucao | any> {
  try {
    const { data } = await api.get<AxiosResponse<IGrauInstrucao>>('/grau_instrucao/' + usuarioId);
    return data as unknown as IGrauInstrucao;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IGrauInstrucao[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IGrauInstrucao[]>>('/grau_instrucao');
    return data as unknown as IGrauInstrucao[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(usuario: Partial<IGrauInstrucao>): Promise<IGrauInstrucao | any> {
  try {
    const { data } = await api.put<AxiosResponse<IGrauInstrucao>>('/grau_instrucao/' + usuario.id, {
      ...usuario,
    });
    return (data as unknown as IGrauInstrucao[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: IGrauInstrucao): Promise<IGrauInstrucao | any> {
  try {
    const { data } = await api.post<AxiosResponse<IGrauInstrucao>>('/grau_instrucao/', {
      ...usuario,
    });
    return (data as unknown as IGrauInstrucao[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: IGrauInstrucao): Promise<IGrauInstrucao | any> {
  try {
    if (usuario.id) {
      return await update(usuario).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(usuario).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<IGrauInstrucao | any> {
  try {
    return await api
      .delete<AxiosResponse<IGrauInstrucao>>('/grau_instrucao/' + usuarioId)
      .then(() => {
        InfoNotification('Registro excluido!');
      });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

import { AxiosError } from 'axios';
import { IMenuItem } from './app.models';
import { ErrorNotification } from './app.notification';
import { IAssociadoFoto } from './Pages/Associado/model';

export const LOCAL_STORAGE_JWT = '@sindojus-admin:j';
export const LOCAL_STORAGE_RJWT = '@sindojus-admin:jr';
export const LOCAL_STORAGE_USER = '@sindojus-admin:user';
export const LOCAL_STORAGE_PREFIX = '@sindojus-admin:';
export const API_URL = process.env.REACT_APP_API_URL || '';

export function AxiosErrorResponse(error: AxiosError) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (error.response?.data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const type = typeof (error.response?.data as any).message;
    if (type === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const template = (error.response?.data as any).message;
      ErrorNotification(template);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const messages = (error.response?.data as any).message as string[];
    const template =
      '<ul>' + messages.reduce((acc, cur) => acc.concat('<li>', cur, '</li>'), '') + '</ul>';
    ErrorNotification(template);
    return;
  }

  if (error?.message) {
    ErrorNotification(error.message);
    return;
  }
}

export const PATHS = {
  dashboard: '/',
  login: '/login',
  profile: '/profile',
  notFound: '*',
  unauthorized: '/nao-autorizada',

  usuario: '/configurações/usuario',
  usuarioInsert: '/configurações/usuario/inserir',
  usuarioEdit: (id?: string): string =>
    id ? '/configurações/usuario/editar/' + id : '/configurações/usuario/editar/:id',

  estadoCivil: '/cadastros/estado-civil',
  estadoCivilInsert: '/cadastros/estado-civil/inserir',
  estadoCivilEdit: (id?: string): string =>
    id ? '/cadastros/estado-civil/editar/' + id : '/cadastros/estado-civil/editar/:id',

  grauInstrucao: '/cadastros/grau-instrucao',
  grauInstrucaoInsert: '/cadastros/grau-instrucao/inserir',
  grauInstrucaoEdit: (id?: string): string =>
    id ? '/cadastros/grau-instrucao/editar/' + id : '/cadastros/grau-instrucao/editar/:id',

  orgaoEmissor: '/cadastros/orgao-emissor',
  orgaoEmissorInsert: '/cadastros/orgao-emissor/inserir',
  orgaoEmissorEdit: (id?: string): string =>
    id ? '/cadastros/orgao-emissor/editar/' + id : '/cadastros/orgao-emissor/editar/:id',

  situacaoFuncional: '/cadastros/situacao-funcional',
  situacaoFuncionalInsert: '/cadastros/situacao-funcional/inserir',
  situacaoFuncionalEdit: (id?: string): string =>
    id ? '/cadastros/situacao-funcional/editar/' + id : '/cadastros/situacao-funcional/editar/:id',

  interesseEducacional: '/cadastros/interesse-educacional',
  interesseEducacionalInsert: '/cadastros/interesse-educacional/inserir',
  interesseEducacionalEdit: (id?: string): string =>
    id
      ? '/cadastros/interesse-educacional/editar/' + id
      : '/cadastros/interesse-educacional/editar/:id',

  coordenadoria: '/cadastros/coordenadoria',
  coordenadoriaInsert: '/cadastros/coordenadoria/inserir',
  coordenadoriaEdit: (id?: string): string =>
    id ? '/cadastros/coordenadoria/editar/' + id : '/cadastros/coordenadoria/editar/:id',

  associado: '/associado',
  associadoInsert: '/associado/inserir',
  associadoEdit: (id?: string): string =>
    id ? '/associado/editar/' + id : '/associado/editar/:id',

  comarca: '/cadastros/municipio',
  comarcaInsert: '/cadastros/municipio/inserir',
  comarcaEdit: (id?: string): string =>
    id ? '/cadastros/municipio/editar/' + id : '/cadastros/municipio/editar/:id',

  lotacao: '/cadastros/lotacao',
  lotacaoInsert: '/cadastros/lotacao/inserir',
  lotacaoEdit: (id?: string): string =>
    id ? '/cadastros/lotacao/editar/' + id : '/cadastros/lotacao/editar/:id',

  concurso: '/cadastros/concurso',
  concursoInsert: '/cadastros/concurso/inserir',
  concursoEdit: (id?: string): string =>
    id ? '/cadastros/concurso/editar/' + id : '/cadastros/concurso/editar/:id',

  contato: '/cadastros/contato',
  contatoInsert: '/cadastros/contato/inserir',
  contatoEdit: (id?: string): string =>
    id ? '/cadastros/contato/editar/' + id : '/cadastros/contato/editar/:id',

  mesoRegiao: '/cadastros/meso-regiao',
  mesoRegiaoInsert: '/cadastros/meso-regiao/inserir',
  mesoRegiaoEdit: (id?: string): string =>
    id ? '/cadastros/meso-regiao/editar/' + id : '/cadastros/meso-regiao/editar/:id',

  microRegiao: '/cadastros/micro-regiao',
  microRegiaoInsert: '/cadastros/micro-regiao/inserir',
  microRegiaoEdit: (id?: string): string =>
    id ? '/cadastros/micro-regiao/editar/' + id : '/cadastros/micro-regiao/editar/:id',

  vinculo: '/cadastros/vinculo',
  vinculoInsert: '/cadastros/vinculo/inserir',
  vinculoEdit: (id?: string): string =>
    id ? '/cadastros/vinculo/editar/' + id : '/cadastros/vinculo/editar/:id',
};

export const MENU_ITENS: IMenuItem[] = [
  {
    title: 'Dashboard',
    path: PATHS.dashboard,
  },
  {
    title: 'Cadastros',
    subItems: [
      {
        label: 'Estado Civil',
        path: PATHS.estadoCivil,
      },
      {
        label: 'Graus de Instruções',
        path: PATHS.grauInstrucao,
      },
      {
        label: 'Orgãos Emissores',
        path: PATHS.orgaoEmissor,
      },
      {
        label: 'Situações Funcionais',
        path: PATHS.situacaoFuncional,
      },
      {
        label: 'Interesses Educacionais',
        path: PATHS.interesseEducacional,
      },
      {
        label: 'Coordenadorias',
        path: PATHS.coordenadoria,
      },
      {
        label: 'Concursos',
        path: PATHS.concurso,
      },
      {
        label: 'Mesoregiões',
        path: PATHS.mesoRegiao,
      },
      {
        label: 'Microregiões',
        path: PATHS.microRegiao,
      },
      {
        label: 'Lotação',
        path: PATHS.lotacao,
      },
      {
        label: 'Tipos de Contatos',
        path: PATHS.contato,
      },
      {
        label: 'Vínculos',
        path: PATHS.vinculo,
      },
      {
        label: 'Municípios',
        path: PATHS.comarca,
      },
    ],
  },
  {
    title: 'Associados',
    path: PATHS.associado,
  },
  {
    title: 'Configurações',
    subItems: [
      {
        label: 'Usuários',
        path: PATHS.usuario,
      },
    ],
  },
];

export function getImageAssociadoPath(image: IAssociadoFoto | undefined): string {
  if (!image || !image.path || !image.filename) return '';
  const path = `${process.env.REACT_APP_API_URL}${image.path.replace('./upload', '')}/${image.filename}`;
  return path;
}

export function convertOneImage(array: any[], id: any, folder: any) {
  // groupBy ARRAY por uuid
  const result = array.reduce(
    (r, v, i, a, k = v.uuid) => ((r[k] || (r[k] = [])).push(v), r),
    {}
  );

  const values = Object.values(result);

  const images = values.map((r: any) => ({
    img: `${process.env.REACT_APP_API_IMG_URL}/images/${folder}/${id}/${r[0].filename}`,
    // img: constroiPath(r[0]),
  }));

  return images;
}

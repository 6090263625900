import classNames from 'classnames';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { IAssociado } from './model';

interface Props {
  register: UseFormRegister<IAssociado>;
  watch: UseFormWatch<IAssociado>;
}

export default function FormAssociadoAnotacoes({ register, watch }: Props) {
  return (
    <div className="space-y-6">
      {/* linha 1 */}
      <div className="grid grid-cols-12 gap-4">
        {/* temDivida */}
        <label className="flex flex-col col-span-12">
          <span>Existem Dívidas</span>
          <div className="flex space-x-2">
            <input
              type="checkbox"
              className={classNames(
                'form-switch h-5 w-10 rounded-full',
                'before:rounded-full before:bg-slate-50 ',
                'bg-slate-300 checked:bg-primary checked:before:bg-white',
                'dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white',
              )}
              {...register('temDivida')}
            />
            <span>{watch('temDivida', false) ? 'Sim' : 'Não'}</span>
          </div>
        </label>
        {/* obs */}
        <label htmlFor="obs" className="flex flex-col col-span-12">
          <div className="flex space-x-2">
            <textarea
              id="obs"
              rows={8}
              className={classNames('form-textarea rounded-lg w-full')}
              {...register('obs')}
            />
          </div>
        </label>
      </div>
    </div>
  );
}

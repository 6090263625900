import { createContext, useContext, useEffect, useState } from 'react';
import { IComarca } from './model';
import { getByUF } from './service';

interface IContext {
  loading: boolean;
  uf: string | undefined;
  setUf: (uf: string | undefined) => void;
  filter: string | undefined;
  setFilter: (uf: string | undefined) => void;
  list: IComarca[];
  current: IComarca | undefined;
  setCurrent: (current: IComarca) => void;
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IComarca[]>([]);
  const [list, setList] = useState<IComarca[]>([]);
  const [current, setCurrent] = useState<IComarca>();
  const [uf, setUf] = useState<string>();
  const [filter, setFilter] = useState<string>();

  useEffect(() => {
    if (!uf) return;
    setLoading(true);
    getByUF(uf)
      .then((data) => {
        if (data) {
          setData(data);
        }
      })
      .finally(() => setLoading(false));
  }, [uf]);

  useEffect(() => {
    if (data.length === 0) return;

    const filtered = data.filter((d) => {
      if (!filter) return true;

      return (
        d.nome.toLowerCase().includes(filter.toLowerCase()) ||
        d.coordenadoria.descricao.toLowerCase().includes(filter.toLowerCase()) ||
        d.mesoRegiao.descricao.toLowerCase().includes(filter.toLowerCase()) ||
        d.microRegiao.descricao.toLowerCase().includes(filter.toLowerCase())
      );
    });
    setList(filtered.sort((a, b) => a.nome.localeCompare(b.nome)));
  }, [filter, data]);

  return (
    <Context.Provider
      value={{
        loading,
        list,
        current,
        setCurrent,
        uf,
        setUf,
        filter,
        setFilter,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useComarca = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useComarca, Provider as ComarcaProvider };

import classNames from 'classnames';
import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../../app.settings';
import { BreadcrumbsComp } from '../../../Components/BreadcrumbsComp';
import { ButtonBackComp } from '../../../Components/ButtonBackComp';
import { ButtonComp } from '../../../Components/ButtonComp';
import { ButtonDeleteComp } from '../../../Components/ButtonDeleteComp';
import LoadingPageComp from '../../../Components/LoadingPageComp';
import { IVinculo } from './model';
import { getById, save, remove } from './service';

export default function VinculoForm() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IVinculo>();

  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [locationState, setLocationState] = useState<any>();

  const loadForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (record: Record<string, any>) => {
      const entries = Object.entries(record);
      entries.forEach(([key, value]) => {
        switch (key) {
          default:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(key as any, value);
        }
      });
    },
    [setValue],
  );

  const init = useCallback(async () => {
    if (params?.id) {
      setLoading(true);
      getById(params.id)
        .then(loadForm)
        .finally(() => setLoading(false));
    } else {
      // insere campos default
      setValue('isVisible', true);
      setValue('ordenacao', 1);
    }
  }, [loadForm, params.id, setValue]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setLocationState(location.state);
  }, [location]);

  const navigateToList = async (sleep: number) => {
    await new Promise((t) => setTimeout(t, sleep)).then(() => {
      navigate(PATHS.vinculo, { state: locationState });
    });
  };

  const submit = (data: IVinculo) => {
    data.ordenacao = data.ordenacao ? Number(data.ordenacao) : 0;
    save(data).then(loadForm);
    navigateToList(1000);
  };

  const del = () => {
    const id = getValues('id').toString();
    if (id) {
      remove(id).then(loadForm);
      navigateToList(1000);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="divide-y space-y-4">
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp
          title={params?.id ? 'Alterar' : 'Inserir'}
          paths={[
            {
              label: 'Vínculos',
              onClick: () => navigateToList(0),
            },
          ]}
        />
        <div className="flex space-x-2">
          <ButtonBackComp label="Voltar" type="button" onClick={() => navigateToList(0)} />
          {params?.id && (
            <ButtonDeleteComp confirm={true} label="Excluir" type="button" onClick={del} />
          )}
          <ButtonComp label="Salvar" type="submit" />
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingPageComp />
        ) : (
          <div className="max-w-lg space-y-2">
            <label htmlFor="descricao" className="flex flex-col">
              <span>Descrição</span>
              <input
                id="descricao"
                type="text"
                className="rounded-lg"
                placeholder="Descrição"
                {...register('descricao', {
                  required: {
                    value: true,
                    message: '(*) Campo requerido',
                  },
                })}
              />
              {errors?.descricao && (
                <span className="text-red-500 text-xs">{errors?.descricao?.message}</span>
              )}
            </label>
            <div className="flex justify-between">
              <label htmlFor="isVisible" className="inline-flex items-center space-x-3">
                <input
                  id="isVisible"
                  type="checkbox"
                  className={classNames(
                    'form-switch h-5 w-10 rounded-full',
                    'before:rounded-full before:bg-slate-50 ',
                    'bg-slate-300 checked:bg-primary checked:before:bg-white',
                    'dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white',
                  )}
                  {...register('isVisible')}
                />
                <span>{watch('isVisible', false) ? 'Ativo' : 'Inativo'}</span>
              </label>
              <label htmlFor="ordenacao" className="inline-flex items-center space-x-3">
                <input
                  id="ordenacao"
                  type="number"
                  className="rounded-lg text-sm w-16"
                  {...register('ordenacao')}
                />
                <span>Ordenação</span>
              </label>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

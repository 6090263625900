import { Flowbite } from 'flowbite-react';
import { Outlet } from 'react-router-dom';
import { AppHeader } from './AppHeader';
import { AppLeftPanel } from './AppLeftPanel';
import { AppWrapper } from './AppWrapper';

export function Layout() {
  return (
    <Flowbite
      theme={{
        theme: {
          button: {
            color: {
              primary: 'bg-primary text-white hover:bg-primary-focus hover:text-slate-100',
              secondary:
                'bg-secondary-lite text-white hover:bg-secondary-focus hover:text-slate-100',
            },
          },
        },
      }}
    >
      <div className="@container/layout font-display">
        <AppHeader />
        <AppLeftPanel />
        <AppWrapper>
          <Outlet />
        </AppWrapper>
      </div>
    </Flowbite>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { usePopper } from 'react-popper';
import { NavLink, useLocation } from 'react-router-dom';

interface Props {
  title: string;
  items: {
    label: string;
    path: string;
  }[];
}

export function AppMenuDropdown({ title, items }: Props) {
  const location = useLocation();
  const paths = location.pathname.split('/');
  const [referenceElement, setReferenceElement] = useState({} as any);
  const [popperElement, setPopperElement] = useState({} as any);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 14] } }],
  });

  return (
    <div className="h-full">
      <Menu as="div" className="relative inline-block text-left z-[500]">
        {({ open }) => (
          <>
            <Menu.Button
              ref={setReferenceElement}
              className={classNames(
                'mx-3 flex items-center  hover:text-secondary-focus text-sm',
                paths.includes(title.toLowerCase()) ? 'text-secondary' : 'text-white',
              )}
            >
              <span>{title}</span>
              <ChevronDownIcon
                className={classNames(
                  'ml-2 -mr-1 h-5 w-5 hover:text-secondary-focus ',
                  open ? 'rotate-0' : '-rotate-180',
                )}
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className={classNames(
                  items.length > 6 ? 'grid grid-cols-2 gap-4' : 'flex flex-col',
                  items.length > 6 ? 'w-[40vw]' : 'w-[20vw]',
                  'bg-slate-50 z-50 relative',
                  'rounded-lg shadow-md p-4',
                  // 'divide-y',
                )}
              >
                {items
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((item, idx) => (
                    <Menu.Item key={idx}>
                      {({ active }) => (
                        <NavLink
                          className={({ isActive }) =>
                            classNames(
                              `${active && 'bg-gray-200'}`,
                              'px-4 py-2 text-sm rounded-lg',
                              isActive ? 'text-secondary' : 'text-primary',
                              'grid grid-cols-[_1fr_10fr] gap-1 place-items-center place-self-start',
                            )
                          }
                          to={item.path}
                        >
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                            />
                          </svg>
                          <span className="w-full">{item.label}</span>
                        </NavLink>
                      )}
                    </Menu.Item>
                  ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../../../app.settings';
import { BreadcrumbsComp } from '../../../Components/BreadcrumbsComp';
import { ButtonComp } from '../../../Components/ButtonComp';
import FilterTextComp from '../../../Components/FilterTextComp';
import LoadingPageComp from '../../../Components/LoadingPageComp';
import { IRow, TableComp } from '../../../Components/TableComp';
import { IOrgaoEmissor } from './model';
import { getAll } from './service';

export default function OrgaoEmissorList() {
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [locationState, setLocationState] = useState<any>({});
  const [data, setData] = useState<IOrgaoEmissor[]>([]);
  const [filtered, setFiltered] = useState<IOrgaoEmissor[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>();
  const cols = [
    {
      key: 'descricao',
      desc: 'Descrição',
    },
    {
      key: 'isActive',
      desc: 'Ativo',
    },
  ];

  useEffect(() => {
    setLoading(true);
    getAll()
      .then((data) => data && setData(data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const dataFiltered = data
      .sort((a, b) => a.descricao.localeCompare(b.descricao) && a.ordenacao - b.ordenacao)
      .filter((d) => d.descricao.toLowerCase().includes(filter.toLowerCase()))
      .map((m) => ({
        ...m,
        isActive: m.isVisible ? 'Sim' : 'Não',
      }));
    setFiltered(dataFiltered);
  }, [data, filter]);

  useEffect(() => {
    setLocationState(location.state);
  }, [location]);

  useEffect(() => {
    if (locationState) {
      if (locationState?.currentPage) setCurrentPage(locationState?.currentPage);
      if (locationState?.filter) setFilter(locationState?.filter);
    }
  }, [locationState]);

  // if (loading) return <LoadingPageComp />;

  return (
    <>
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp title="Orgãos Emissores" paths={[]} />
        <ButtonComp
          label="Inserir"
          type="button"
          onClick={() => navigate(PATHS.orgaoEmissorInsert)}
        />
      </div>
      <div className="flex items-center justify-end pb-3">
        <FilterTextComp value={filter} onChange={setFilter} />
      </div>
      <div className="hidden md:block">
        {loading ? (
          <LoadingPageComp />
        ) : (
          <TableComp
            cols={cols}
            rows={filtered.map((el) => {
              const entries = Object.entries(el);
              const tds: unknown[] = [];
              entries.forEach(([key, value]) => {
                tds.push({ key: String(key), value: String(value) });
              });
              return { rowId: el.id, tds } as unknown as IRow;
            })}
            action={(rowId, selectedPage) =>
              navigate(PATHS.orgaoEmissorEdit(rowId), {
                state: { filter, currentPage: selectedPage },
              })
            }
            currentPage={currentPage}
            perPage={!filter ? 6 : undefined}
          />
        )}
      </div>
    </>
  );
}

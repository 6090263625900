import classNames from 'classnames';
import * as Icon from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

interface Props {
  paths: {
    label: string;
    path?: string;
    onClick?: () => void;
  }[];
  title: string;
}

export function BreadcrumbsComp({ paths, title }: Props) {
  return (
    <div
      className={classNames(
        'flex items-center h-8 overflow-hidden',
        'bg-secondary-lite',
        'border border-primary',
        'rounded-lg',
        'min-w-max',
      )}
    >
      <div
        className={classNames(
          'h-8 pl-3 rounded-l-lg text-primary text-opacity-75',
          'flex items-center',
        )}
      >
        <Icon.StarIcon className="w-6 h-6 text-primary" />
      </div>
      <div className={'flex items-center'}>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="0.7"
          stroke="currentColor"
          className=" h-12 text-primary text-opacity-75"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
        {paths.map((p, i) => (
          <div
            key={i}
            className={classNames(' h-8 text-primary text-opacity-75', 'flex items-center')}
          >
            {p.path && (
              <Link className="underline hover:font-bold" to={p.path}>
                {p.label}
              </Link>
            )}
            {p.onClick && (
              <button type="button" className="underline hover:font-bold" onClick={p.onClick}>
                {p.label}
              </button>
            )}
            <svg
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="0.7"
              stroke="currentColor"
              className="h-12 text-primary text-opacity-75"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </div>
        ))}
      </div>
      <div
        className={classNames(
          'h-8 pr-3 rounded-r-lg text-white text-opacity-75',
          'flex items-center',
        )}
      >
        <span>{title}</span>
      </div>
    </div>
  );
}

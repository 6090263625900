import { Control, FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form';
// eslint-disable-next-line no-duplicate-imports
import { Controller } from 'react-hook-form';
import CidadeComboboxComp from '../../Components/CidadeComboboxComp';
import UfComboboxComp from '../../Components/UfComboboxComp';
import { IAssociado } from './model';

import InputMask from 'react-input-mask';

interface Props {
  register: UseFormRegister<IAssociado>;
  control: Control<IAssociado>;
  errors: Partial<FieldErrorsImpl<IAssociado>>;
  watch: UseFormWatch<IAssociado>;
}

export default function FormAssociadoContatos({ register, control, errors, watch }: Props) {
  return (
    <div className="space-y-6">
      {/* linha 1 */}
      <div className="grid grid-cols-12 gap-4">
        {/* emailInstitucional */}
        <label htmlFor="email" className="col-span-6 flex flex-col">
          <span>Email Institucional</span>
          <input
            id="emailInstitucional"
            type="email"
            className="rounded-lg"
            placeholder="Email Institucional"
            {...register('emailInstitucional', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Email inválido',
              },
            })}
          />
          {errors?.emailInstitucional && (
            <span className="text-red-500 text-xs">{errors?.emailInstitucional?.message}</span>
          )}
        </label>
        {/* email */}
        <label htmlFor="email" className="col-span-6 flex flex-col">
          <span>Email</span>
          <input
            id="email"
            type="email"
            className="rounded-lg"
            placeholder="Email"
            {...register('email', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Email inválido',
              },
            })}
          />
          {errors?.email && <span className="text-red-500 text-xs">{errors?.email?.message}</span>}
        </label>
      </div>

      {/* linha 2 */}
      <div className="grid grid-cols-12 gap-4">
        {/* endereco */}
        <label htmlFor="endereco" className="flex flex-col w-full col-span-6">
          <span>Endereço</span>
          <input
            id="endereco"
            type="text"
            className="rounded-lg"
            placeholder="Endereço"
            {...register('endereco', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.endereco && (
            <span className="text-red-500 text-xs">{errors?.endereco?.message}</span>
          )}
        </label>

        {/* complemento */}
        <label htmlFor="complemento" className="flex flex-col w-full col-span-3">
          <span>Complemento</span>
          <input
            id="complemento"
            type="text"
            className="rounded-lg"
            placeholder="Complemento"
            {...register('complemento', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.complemento && (
            <span className="text-red-500 text-xs">{errors?.complemento?.message}</span>
          )}
        </label>

        {/* bairro */}
        <label htmlFor="bairro" className="flex flex-col w-full col-span-3">
          <span>Bairro</span>
          <input
            id="bairro"
            type="text"
            className="rounded-lg"
            placeholder="Bairro"
            {...register('bairro', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.bairro && (
            <span className="text-red-500 text-xs">{errors?.bairro?.message}</span>
          )}
        </label>
      </div>

      {/* linha 3 */}
      <div className="grid grid-cols-12 gap-4">
        {/* cep */}
        <label htmlFor="cep" className="flex flex-col w-full col-span-4">
          <span>CEP</span>
          <InputMask
            id="cep"
            type="text"
            mask="99999-999"
            maskChar=" "
            className="rounded-lg"
            placeholder="CEP"
            {...register('cep', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.cep && <span className="text-red-500 text-xs">{errors?.cep?.message}</span>}
        </label>

        {/* uf */}
        <label htmlFor="uf" className="col-span-4 flex flex-col z-auto relative">
          <span>UF</span>
          <Controller
            control={control}
            name={'uf'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <UfComboboxComp value={field.value as unknown as string} onChange={field.onChange} />
            )}
          />

          {errors?.uf && <span className="text-red-500 text-xs">{errors?.uf?.message}</span>}
        </label>

        {/* municipio */}
        <label htmlFor="municipio" className="col-span-4 flex flex-col z-auto relative">
          <span
            className={watch('uf', undefined) === undefined ? 'text-slate-400' : 'text-inherit'}
          >
            Cidade
          </span>
          <Controller
            control={control}
            name={'municipio'}
            rules={{
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            }}
            render={({ field }) => (
              <CidadeComboboxComp
                value={field.value as unknown as string}
                onChange={field.onChange}
                uf={watch('uf', undefined)}
                disabled={watch('uf', undefined) === undefined}
              />
            )}
          />

          {errors?.municipio && (
            <span className="text-red-500 text-xs">{errors?.municipio?.message}</span>
          )}
        </label>
      </div>

      {/* linha 4 */}
      <div className="grid grid-cols-12 gap-4">
        {/* cel1 */}
        <label htmlFor="cel1" className="flex flex-col w-full col-span-3">
          <span>Celular 1</span>
          <InputMask
            id="cel1"
            type="text"
            mask="(99) 99999-9999"
            maskChar=" "
            className="rounded-lg"
            placeholder="Celular 1"
            {...register('cel1', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.cel1 && <span className="text-red-500 text-xs">{errors?.cel1?.message}</span>}
        </label>

        {/* cel2 */}
        <label htmlFor="cel2" className="flex flex-col w-full col-span-3">
          <span>Celular 2</span>
          <InputMask
            id="cel2"
            type="text"
            mask="(99) 99999-9999"
            maskChar=" "
            className="rounded-lg"
            placeholder="Celular 2"
            {...register('cel2', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.cel2 && <span className="text-red-500 text-xs">{errors?.cel2?.message}</span>}
        </label>

        {/* cel3 */}
        <label htmlFor="cel3" className="flex flex-col w-full col-span-3">
          <span>Celular 3</span>
          <InputMask
            id="cel3"
            type="text"
            className="rounded-lg"
            mask="(99) 99999-9999"
            maskChar=" "
            placeholder="Celular 3"
            {...register('cel3', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.cel3 && <span className="text-red-500 text-xs">{errors?.cel3?.message}</span>}
        </label>

        {/* fixo */}
        <label htmlFor="fixo" className="flex flex-col w-full col-span-3">
          <span>Fixo Residencial</span>
          <InputMask
            id="fixo"
            type="text"
            className="rounded-lg"
            mask="(99) 9999-9999"
            maskChar=" "
            placeholder="Fixo Residencial"
            {...register('fixo', {
              required: {
                value: false,
                message: '(*) Campo requerido',
              },
            })}
          />
          {errors?.fixo && <span className="text-red-500 text-xs">{errors?.fixo?.message}</span>}
        </label>
      </div>
    </div>
  );
}

import classNames from 'classnames';
import LogoSVG from '../assets/svg/logo.svg';
import NameSVG from '../assets/svg/name.svg';
import { useAuth } from '../Auth/Context';
import { AppMenu } from './AppMenu';
import { AppMenuDropdown } from './AppMenuDropdown';
import { AppMenuUser } from './AppMenuUser';
import { AppWrapper } from './AppWrapper';
import { useLayout } from './Context';

export function AppHeader() {
  const { currentUser } = useAuth();
  const { menuLeftIsOpen, setMenuLeftIsOpen, menuItems } = useLayout();
  return (
    <div className="relative h-12 bg-primary @container/header z-[100]">
      {/* menu mobile */}
      <div className="@md:hidden absolute z-50 w-screen h-full flex justify-between items-center">
        <img src={LogoSVG} className="h-full" />
        <div className="h-full py-2">
          <img src={NameSVG} className="h-full fill-white" />
        </div>
        <button
          type="button"
          onClick={() => setMenuLeftIsOpen(!menuLeftIsOpen)}
          className={classNames(
            'mx-2 inline-flex items-center justify-center rounded-md  p-2 text-white',
            // 'focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white',
            menuLeftIsOpen ? 'bg-black/50' : 'bg-black/20',
          )}
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          <svg
            className="hidden h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div className="hidden @md:block absolute z-50 w-screen">
        <AppWrapper>
          <div className="h-12 grid grid-cols-12 content-center">
            <div className="col-span-9 flex items-center">
              <img src={LogoSVG} className="h-12" />
              <div className="h-6 flex divide-x divide-white/20">
                {menuItems.map((mi, idx) =>
                  mi.subItems ? (
                    <AppMenuDropdown key={idx} title={mi.title} items={mi.subItems} />
                  ) : (
                    <AppMenu key={idx} title={mi.title} path={mi.path || ''} />
                  ),
                )}
              </div>
            </div>
            <div className="col-span-3 flex justify-end items-center">
              <AppMenuUser
                name={currentUser?.nome.toUpperCase() || 'N/D'}
                info={currentUser?.perfil.toLowerCase() || 'N/D'}
              />
            </div>
          </div>
        </AppWrapper>
      </div>
    </div>
  );
}

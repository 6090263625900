/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { AxiosErrorResponse, LOCAL_STORAGE_PREFIX } from './app.settings';

import api from './axios';
// import { IUsuario } from './Pages/Usuario/model';

export interface IUf {
  uf: string;
  descricao: string;
  isVisible: boolean;
  ordenacao: number;
}

export interface ICidade {
  ibge: number;
  nome: string;
  uf: string;
}

export async function getAllUf(): Promise<IUf[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IUf[]>>('/uf');
    return data as unknown as IUf[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function getAllCidades(): Promise<ICidade[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<ICidade[]>>('/municipio');
    return data as unknown as ICidade[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export function setFormLocalStorage(formIdentification: string, value: string): void {
  localStorage.setItem(LOCAL_STORAGE_PREFIX + formIdentification, value);
}

export function getFormLocalStorage(formIdentification: string): string | null {
  return localStorage.getItem(LOCAL_STORAGE_PREFIX + formIdentification);
}

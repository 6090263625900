import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
  src: string;
  alt: string;
  height: number;
  width: number;
}

function LazyLoadImageComp({ src, alt, height, width }: Props) {
  return (
    <div>
      <LazyLoadImage
        alt={alt}
        height={height}
        src={src} // use normal <img> attributes as props
        width={width}
      />
    </div>
  );
}

export default LazyLoadImageComp;

import { useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../../app.settings';
import { BreadcrumbsComp } from '../../../Components/BreadcrumbsComp';
import { ButtonBackComp } from '../../../Components/ButtonBackComp';
import { ButtonComp } from '../../../Components/ButtonComp';
import { ButtonDeleteComp } from '../../../Components/ButtonDeleteComp';
import CidadeComboboxComp from '../../../Components/CidadeComboboxComp';
import LoadingPageComp from '../../../Components/LoadingPageComp';
import UfComboboxComp from '../../../Components/UfComboboxComp';
import ComarcaComboboxComp from '../Comarcas/ComboboxComp';
import { useLotacao } from './context';
import { ILotacao } from './model';
import { save, remove } from './service';

export default function LotacaoForm() {
  const params = useParams();
  const { current, loading, setCurrentById } = useLotacao();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<ILotacao>();

  const loadForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (record: Record<string, any>) => {
      if (!record) return;
      const entries = Object.entries(record);
      entries.forEach(([key, value]) => {
        switch (key) {
          case 'municipioComarca':
          case 'municipio': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(key as any, value.ibge);
            break;
          }

          default:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(key as any, value);
        }
      });
    },
    [setValue],
  );

  const init = useCallback(async () => {
    if (current) {
      loadForm(current);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const id = (params as any).id;
      if (params?.id) {
        setCurrentById(parseInt(id));
      } else {
        navigate(PATHS.lotacao);
      }
    }
  }, [current, loadForm, navigate, params, setCurrentById]);

  useEffect(() => {
    init();
  }, [init]);

  const navigateToList = async (sleep: number) => {
    await new Promise((t) => setTimeout(t, sleep)).then(() => {
      navigate(PATHS.lotacao);
    });
  };

  const submit = (data: ILotacao) => {
    // eslint-disable-next-line camelcase
    data.updated_at = new Date();
    save(data).then(loadForm);
    navigateToList(1000);
  };

  const del = () => {
    const id = getValues('id').toString();
    if (id) {
      remove(id).then(loadForm);
      navigateToList(1000);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="divide-y space-y-4">
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp
          title={current?.id ? 'Alterar' : 'Inserir'}
          paths={[
            {
              label: 'Lotação',
              onClick: () => navigateToList(0),
            },
          ]}
        />
        <div className="flex space-x-2">
          <ButtonBackComp label="Voltar" type="button" onClick={() => navigateToList(0)} />
          {current?.id && (
            <ButtonDeleteComp confirm={true} label="Excluir" type="button" onClick={del} />
          )}
          <ButtonComp label="Salvar" type="submit" />
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingPageComp />
        ) : (
          <div className="w-full space-y-6">
            {/* linha 1 */}
            <div className="grid grid-cols-12 gap-4">
              {/* local */}
              <label htmlFor="local" className="col-span-5 flex flex-col w-full">
                <span>Local</span>
                <input
                  id="local"
                  type="text"
                  className="rounded-lg"
                  placeholder="Local"
                  {...register('local', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.local && (
                  <span className="text-red-500 text-xs">{errors?.local?.message}</span>
                )}
              </label>

              {/* tel */}
              <label htmlFor="tel" className="col-span-3 flex flex-col w-full">
                <span>Telefone</span>
                <input
                  id="tel"
                  type="text"
                  className="rounded-lg"
                  placeholder="Telefone"
                  {...register('tel', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.tel && (
                  <span className="text-red-500 text-xs">{errors?.tel?.message}</span>
                )}
              </label>

              {/* comarca */}
              <label htmlFor="comarca" className="col-span-4 flex flex-col z-auto relative">
                <span>Comarca</span>
                {/* <Controller
                  control={control}
                  name={'comarca'}
                  rules={{
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  }}
                  render={({ field }) => (
                    <ComarcaComboboxComp value={Number(field.value)} onChange={field.onChange} />
                  )}
                /> */}
                <input
                  id="comarca"
                  type="text"
                  className="rounded-lg"
                  placeholder="Local"
                  {...register('comarca', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.comarca && (
                  <span className="text-red-500 text-xs">{errors?.comarca?.message}</span>
                )}
              </label>
            </div>

            {/* linha 2 */}
            <div className="grid grid-cols-12 gap-4">
              {/* endereco */}
              <label htmlFor="endereco" className="col-span-6 flex flex-col w-full">
                <span>Endereço</span>
                <input
                  id="endereco"
                  type="text"
                  className="rounded-lg"
                  placeholder="Endereço"
                  {...register('endereco', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.endereco && (
                  <span className="text-red-500 text-xs">{errors?.endereco?.message}</span>
                )}
              </label>

              {/* complemento */}
              <label htmlFor="complemento" className="col-span-3 flex flex-col w-full">
                <span>Complemento</span>
                <input
                  id="complemento"
                  type="text"
                  className="rounded-lg"
                  placeholder="Complemento"
                  {...register('complemento', {
                    required: {
                      value: false,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.complemento && (
                  <span className="text-red-500 text-xs">{errors?.complemento?.message}</span>
                )}
              </label>

              {/* bairro */}
              <label htmlFor="bairro" className="col-span-3 flex flex-col w-full">
                <span>Bairro</span>
                <input
                  id="bairro"
                  type="text"
                  className="rounded-lg"
                  placeholder="Bairro"
                  {...register('bairro', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.bairro && (
                  <span className="text-red-500 text-xs">{errors?.bairro?.message}</span>
                )}
              </label>
            </div>

            {/* linha 3 */}
            <div className="grid grid-cols-12 gap-4">
              {/* cep */}
              <label htmlFor="cep" className="col-span-4 flex flex-col w-full">
                <span>Cep</span>
                <input
                  id="cep"
                  type="text"
                  className="rounded-lg"
                  placeholder="Cep"
                  {...register('cep', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.cep && (
                  <span className="text-red-500 text-xs">{errors?.cep?.message}</span>
                )}
              </label>

              {/* uf */}
              <label htmlFor="uf" className="col-span-4 flex flex-col z-auto relative">
                <span>UF</span>
                <Controller
                  control={control}
                  name={'uf'}
                  rules={{
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  }}
                  render={({ field }) => (
                    <UfComboboxComp
                      value={field.value as unknown as string}
                      onChange={field.onChange}
                    />
                  )}
                />

                {errors?.uf && <span className="text-red-500 text-xs">{errors?.uf?.message}</span>}
              </label>

              {/* municipio */}
              <label htmlFor="municipio" className="col-span-4 flex flex-col z-auto relative">
                <span>Cidade</span>
                <Controller
                  control={control}
                  name={'municipio'}
                  rules={{
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  }}
                  render={({ field }) => (
                    <CidadeComboboxComp
                      value={field.value as unknown as string}
                      onChange={field.onChange}
                      uf={watch('uf', undefined)}
                    />
                  )}
                />

                {errors?.municipio && (
                  <span className="text-red-500 text-xs">{errors?.municipio?.message}</span>
                )}
              </label>
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

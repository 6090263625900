interface Props {
  image?: string;
  name: string;
  info?: string;
}

export function AvatarComp({ image, name, info }: Props) {
  return (
    <div className="flex items-center space-x-2">
      <div className="flex h-8 w-8 p-[1px] rounded-full ring-2 ring-white">
        {image ? (
          <img className="rounded-full" src={image} alt="" />
        ) : (
          <div className="bg-white flex justify-center items-center rounded-full w-full h-full">
            <span className="text-primary font-bold text-3xl">{name.substring(0, 1)}</span>
          </div>
        )}
      </div>
      <div className="flex flex-col items-start">
        <div className="text-white text-sm line-clamp-1 text-left overflow-hidden w-24">{name}</div>
        {info && <div className="text-white text-xs line-clamp-1 text-left">{info}</div>}
      </div>
    </div>
  );
}

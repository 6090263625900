import { useState } from 'react';

import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { ButtonComp } from '../../Components/ButtonComp';

interface Props {
  action: (newPasswor: string) => void;
  label?: string;
  id?: string;
}

export default function ModalUpdatePassword(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  function closeModal() {
    setIsOpen(false);
  }

  const confirm = () => {
    if (!newPassword || !confirmPassword) {
      setError('Preencha os campos');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }

    props.action(newPassword);
    closeModal();
  };

  return (
    <div id={props.id}>
      <ButtonComp
        onClick={() => setIsOpen(true)}
        label={props.label ?? 'Altera minha senha'}
        type="button"
        icon={<LockClosedIcon className="w-4 h-4" />}
      />
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
        open={isOpen}
      >
        <div className="min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-20" />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <div className="inline-block w-full max-w-xl p-0 my-1 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="flex justify-between mb-2 bg-primary2 text-default-white px-4 py-2"
            >
              <span className="text-lg font-bold leading-6 font-display">Alterar Senha</span>
              <button onClick={closeModal} className="hover:text-gray-400">
                <XMarkIcon className="w-5 h-5" />
              </button>
            </Dialog.Title>
            <div className="p-4 my-2 space-y-2">
              <label htmlFor="nome" className="flex flex-col">
                <span>Senha</span>
                <input
                  type="password"
                  className="rounded-lg"
                  placeholder="Nova senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </label>
              <label htmlFor="nome" className="flex flex-col">
                <span>Confirmação de Senha</span>
                <input
                  type="password"
                  className="rounded-lg"
                  placeholder="Confirmação de senha"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </label>
              {error && (
                <div className="text-red-100 text-md bg-red-500 px-2 py-1 my-1 rounded-md">
                  {error}
                </div>
              )}
              <div className="text-right">
                <ButtonComp label="Alterar" type="button" onClick={confirm} />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

import { useState, useEffect, useRef } from 'react';
import { Checkbox, Label } from 'flowbite-react';
import classNames from 'classnames';
import CidadeComboboxComp from '../../../Components/CidadeComboboxComp';
import UfComboboxComp from '../../../Components/UfComboboxComp';

interface Props {
  action: (record: Record<string, any>) => void;
  clearFilter?: number;
}

export default function CheckFilterMunicipio({ action, clearFilter }: Props) {
  const [active, setActive] = useState(false);
  const [uf, setUf] = useState('CE');
  const [municipio, setMunicipio] = useState('');

  useEffect(() => {
    if (active && municipio) {
      action({ municipio });
    } else {
      action({});
    }
  }, [action, active, municipio]);

  const lastCount = useRef(0);

  useEffect(() => {
    if (!active) return;
    if (!clearFilter) return;
    const current = lastCount.current;
    if (clearFilter !== current) {
      setActive(false);
      lastCount.current = clearFilter;
    }
  }, [active, clearFilter]);

  useEffect(() => {
    if (!clearFilter) return;
    lastCount.current = clearFilter;
  }, [clearFilter]);

  return (
    <div
      className={classNames(
        'flex flex-col space-y-2',
        'p-2 rounded-lg',
        active ? 'bg-slate-400 ' : 'bg-slate-200',
        'text-slate-900',
        active ? 'font-bold' : '',
      )}
    >
      <div className="flex items-center gap-2">
        <Checkbox
          id="check"
          defaultChecked={false}
          checked={active}
          onChange={() => setActive(!active)}
        />
        <Label htmlFor="check" className={classNames(active === false && 'text-slate-500')}>
          Município{' '}
        </Label>
      </div>
      <div className="flex flex-col space-y-2">
        {/* uf */}
        <div className="w-full">
          <UfComboboxComp value={uf} onChange={setUf} disabled={true} min={true} />
        </div>
        {/* municipio */}

        <div className="w-full">
          <CidadeComboboxComp
            value={municipio}
            onChange={setMunicipio}
            uf={uf}
            min={true}
            disabled={!active}
          />
        </div>
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IEstadoCivil } from './model';

export async function getById(usuarioId: string): Promise<IEstadoCivil | any> {
  try {
    const { data } = await api.get<AxiosResponse<IEstadoCivil>>('/estado_civil/' + usuarioId);
    return data as unknown as IEstadoCivil;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IEstadoCivil[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IEstadoCivil[]>>('/estado_civil');
    return data as unknown as IEstadoCivil[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(usuario: Partial<IEstadoCivil>): Promise<IEstadoCivil | any> {
  try {
    const { data } = await api.put<AxiosResponse<IEstadoCivil>>('/estado_civil/' + usuario.id, {
      ...usuario,
    });
    return (data as unknown as IEstadoCivil[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: IEstadoCivil): Promise<IEstadoCivil | any> {
  try {
    const { data } = await api.post<AxiosResponse<IEstadoCivil>>('/estado_civil/', { ...usuario });
    return (data as unknown as IEstadoCivil[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: IEstadoCivil): Promise<IEstadoCivil | any> {
  try {
    if (usuario.id) {
      return await update(usuario).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(usuario).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<IEstadoCivil | any> {
  try {
    return await api.delete<AxiosResponse<IEstadoCivil>>('/estado_civil/' + usuarioId).then(() => {
      InfoNotification('Registro excluido!');
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

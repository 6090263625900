/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { IInfo } from '../../app.models';
import { InfoNotification } from '../../app.notification';
import { AxiosErrorResponse } from '../../app.settings';

import api from '../../axios';
import { IAssociado } from './model';

export interface IFind {
  associados: IAssociado[];
  info: IInfo;
}

export async function getById(id: string): Promise<IAssociado | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IAssociado>>('/associado/' + id);
    return data as unknown as IAssociado;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function getAll(): Promise<IAssociado[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IAssociado[]>>('/associado');
    return data as unknown as IAssociado[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function find(args: Record<string, any>): Promise<IFind | undefined> {
  const take = args.take || 10;
  // const take = -1;
  const skip = args.skip || 0;
  const orderBy = args.orderBy || 'nome';
  const sort = args.sort || '1';
  const body = args.body || {};
  try {
    const { data } = await api.post<AxiosResponse<IFind>>(
      `/associado/find/?take=${take}&skip=${skip}&orderBy=${orderBy}&sort=${sort}`,
      { ...body },
    );
    return data as unknown as IFind;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(reg: Partial<IAssociado>): Promise<IAssociado | any> {
  try {
    const { data } = await api.put<AxiosResponse<IAssociado>>('/associado/' + reg.id, {
      ...reg,
    });
    return (data as unknown as IAssociado[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(reg: Partial<IAssociado>): Promise<IAssociado | any> {
  try {
    const { data } = await api.post<AxiosResponse<IAssociado>>('/associado/', {
      ...reg,
    });
    return (data as unknown as IAssociado[]);
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(reg: Partial<IAssociado>): Promise<IAssociado | any> {
  try {
    if (reg.id) {
      const upd = await update(reg);
      InfoNotification('Registro alterado!');
      return upd;
    } else {
      reg.municipioId = reg.municipio as unknown as number;

      const ins = await insert(reg);
      console.log('🚀 ~ file: service.ts:83 ~ save ~ ins:', ins)
      InfoNotification('Registro criado!');
      return ins;
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(regId: string): Promise<IAssociado | any> {
  try {
    return await api.delete<AxiosResponse<IAssociado>>('/associado/' + regId).then(() => {
      InfoNotification('Registro excluido!');
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function filiacao(args: Record<string, any>): Promise<IAssociado | any> {
  try {
    const { data } = await api.post<AxiosResponse<IAssociado>>('/filiacao', {
      ...args,
    });
    return (data as unknown as IAssociado[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export function upload(
  id: string,
  file: File,
  h: string,
  w: string,
  onUploadProgress: (progressEvent: any) => void
): Promise<any> {
  const formData = new FormData();
  const url = `/associado/${id}/upload/?w=${w}&h=${h}`;
  formData.append('file', file);
  return api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: onUploadProgress,
  });
}

import classNames from 'classnames';
import { useState } from 'react';
import ModalConfirmation from './ModalConfirmation';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  confirm?: boolean;
  onClick: () => void;
}

export function ButtonDeleteComp({ label, confirm, onClick, ...rest }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        className={classNames(
          'inline-flex w-full items-center justify-center rounded-xl px-4 py-2 text-base font-medium shadow-sm',
          'border border-gray-300',
          'bg-red-500 text-white/75',
          'hover:bg-red-400',
          'focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-offset-2',
          'disabled:bg-slate-400',
          'h-8 sm:w-auto sm:text-sm',
        )}
        onClick={() => {
          if (!confirm) {
            onClick();
            return;
          }
          setIsOpen(true);
        }}
        {...rest}
      >
        {label}
      </button>
      <ModalConfirmation
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={'Confirmação de Exclusão'}
        question={'Excluir?'}
        answer={() => onClick()}
      />
    </>
  );
}

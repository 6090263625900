import { useEffect, useState } from 'react';
import { FieldErrorsImpl, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { IAssociado } from './model';
import classNames from 'classnames';

interface Props {
  register: UseFormRegister<IAssociado>;
  errors: Partial<FieldErrorsImpl<IAssociado>>;
  setValue: UseFormSetValue<IAssociado>;
}

export default function FormEntidadeClasseInsert({ register, errors, setValue }: Props) {
  const [isSendDataAdmissao, setIsSendDataAdmissao] = useState(false);

  useEffect(() => {
    !isSendDataAdmissao && setValue('dataAdmissao', null);
  }, [isSendDataAdmissao, setValue]);

  return (
    <div className="space-y-6">
      <div className="flex flex-col gap-4">
        {/* temDivida */}
        <label className="flex flex-col">
          {/* <span>Data de Admissão</span> */}
          <div className="flex space-x-2">
            <input
              type="checkbox"
              className={classNames(
                'form-switch h-5 w-10 rounded-full',
                'before:rounded-full before:bg-slate-50 ',
                'bg-slate-300 checked:bg-primary checked:before:bg-white',
                'dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white',
              )}
              checked={isSendDataAdmissao}
              onChange={(e) => setIsSendDataAdmissao(e.target.checked)}
            />
            <span>
              Filiado
              {/* {isSendDataAdmissao
                ? 'Desmarque para não enviar data de admissão'
                : 'Marque para enviar uma data de admissão'} */}
            </span>
          </div>
        </label>
        {/* dataAdmissao */}
        {isSendDataAdmissao && (
          <label htmlFor="dataAdmissao" className="flex flex-col max-w-xs">
            <span>Data de Admissão</span>
            <input
              id="dataAdmissao"
              type="date"
              className="rounded-lg"
              placeholder="Nascimento"
              {...register('dataAdmissao', {
                required: {
                  value: isSendDataAdmissao ? true : false,
                  message: '(*) Campo requerido',
                },
              })}
            />
            {errors?.dataAdmissao && (
              <span className="text-red-500 text-xs">{errors?.dataAdmissao?.message}</span>
            )}
          </label>
        )}
      </div>
    </div>
  );
}

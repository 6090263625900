import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button } from 'flowbite-react';
import { ReactComponent as ExcelIcon } from '../assets/svg/arquivo-excel.svg';
import { CSVLink } from 'react-csv';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useAssociado } from '../Pages/Associado/context';

interface Props {
  caption: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  fields: {
    value: string;
    desc: string;
  }[];
}

export default function ExcelDownloadComp({ caption, data, fields }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selecteds, setSelecteds] = useState<string[]>([]);
  const [render, setRender] = useState(0);
  const { loadExcel } = useAssociado();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [excelData, setExcelData] = useState<any[]>([]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    loadExcel();
  }

  useEffect(() => {
    if (selecteds.length === 0) {
      setExcelData([]);
      return;
    }
    const newData = data.map((m) => {
      const filtered = Object.keys(m)
        .filter((key) => selecteds.includes(key))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((obj: any, key: any) => {
          switch (key) {
            case 'concurso': {
              obj['concurso'] = m['concurso'] ? m['concurso']['descricao'] : '';
              break;
            }

            case 'estadoCivil': {
              obj['estadoCivil'] = m['estadoCivil'] ? m['estadoCivil']['descricao'] : '';
              break;
            }

            case 'grauInstrucao': {
              obj['grauInstrucao'] = m['grauInstrucao'] ? m['grauInstrucao']['descricao'] : '';
              break;
            }

            case 'vinculoFuncional': {
              obj['vinculoFuncional'] = m['vinculoFuncional']
                ? m['vinculoFuncional']['descricao']
                : '';
              break;
            }

            case 'situacaoFuncional': {
              obj['situacaoFuncional'] = m['situacaoFuncional']
                ? m['situacaoFuncional']['descricao']
                : '';
              break;
            }

            case 'filiado': {
              obj['filiado'] = m['filiado'] && m['filiado'] ? 'Sim' : 'Não';
              break;
            }

            case 'rgOrgaoEmissor': {
              obj['rgOrgaoEmissor'] = m['rgOrgaoEmissor'] ? m['rgOrgaoEmissor']['descricao'] : '';
              break;
            }

            case 'municipio': {
              obj['municipio'] = m['municipio'] ? m['municipio']['nome'] : '';
              break;
            }

            case 'lotacao': {
              obj['lotacao'] = m['lotacao']
                ? m['lotacao']['comarca'] + ' - ' + m['lotacao']['local']
                : '';
              break;
            }

            case 'nascimento': {
              obj['nascimento'] = m['nascimento']
                ? new Date(m['nascimento'].replace(/-/g, '/')).toLocaleDateString('pt-br')
                : '';
              break;
            }

            case 'sindicatoData': {
              obj['sindicatoData'] = m['sindicatoData']
                ? new Date(m['sindicatoData'].replace(/-/g, '/')).toLocaleDateString('pt-br')
                : '';
              break;
            }

            case 'created_at': {
              obj['created_at'] = m['created_at']
                ? new Date(m['created_at']).toLocaleDateString('pt-br')
                : '';
              break;
            }

            case 'updated_at': {
              obj['updated_at']['updated_at'] = m['updated_at']
                ? new Date(m).toLocaleDateString('pt-br')
                : '';
              break;
            }

            case 'exercicio': {
              obj['exercicio'] = m['exercicio']
                ? new Date(m['exercicio'].replace(/-/g, '/')).toLocaleDateString('pt-br')
                : '';
              break;
            }

            default: {
              obj[key] = m[key] ? m[key] : '';
            }
          }
          return obj;
        }, {});
      return filtered;
    });

    setExcelData(newData);
  }, [data, selecteds, selecteds.length]);

  return (
    <>
      <Button color={'primary'} size="xs" onClick={openModal}>
        <div className="mr-3">
          <ExcelIcon className="w-4 h-4 fill-white" />
        </div>
        {caption}
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl space-y-4 transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Selecione os campos
                  </Dialog.Title>

                  <div className="grid grid-cols-4 gap-2">
                    {fields.map((f) => (
                      <button
                        key={f.value}
                        className={classNames(
                          'text-xs',
                          'flex space-x-2 items-center',
                          'border-[2px] border-solid',
                          'rounded-full py-1 px-1',
                          selecteds.includes(f.value)
                            ? 'text-green-500 border-green-500'
                            : 'text-red-500 border-red-500',
                        )}
                        onClick={() => {
                          const val = f.value;
                          const newSelecteds = selecteds;

                          if (newSelecteds.includes(val)) {
                            const index = newSelecteds.indexOf(val);
                            newSelecteds.splice(index, 1);
                          } else {
                            newSelecteds.push(f.value);
                          }
                          setSelecteds(newSelecteds);
                          setRender(render + 1);
                        }}
                      >
                        {selecteds.includes(f.value) ? (
                          <CheckIcon className="w-6 h-6" />
                        ) : (
                          <XMarkIcon className="w-6 h-6" />
                        )}
                        <span>{f.desc}</span>
                      </button>
                    ))}
                  </div>

                  <CSVLink
                    separator=";"
                    className="inline-block w-full text-center py-2 rounded-full text-white bg-primary focus:bg-primary-focus"
                    data={excelData}
                  >
                    Download
                  </CSVLink>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

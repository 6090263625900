import { Transition } from '@headlessui/react';
import { useLayout } from './Context';

export function AppLeftPanel() {
  const { menuLeftIsOpen } = useLayout();
  return (
    <Transition
      className="@md:hidden w-[70vw] absolute z-30 pt-12 h-screen bg-white shadow-xl"
      as={'div'}
      show={menuLeftIsOpen}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <div className=""></div>
    </Transition>
  );
}

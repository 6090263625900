import { useState, useEffect, useRef } from 'react';
import { Checkbox, Label, Radio } from 'flowbite-react';
import classNames from 'classnames';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: (record: Record<string, any>) => void;
  dataKey: string;
  dataDesc: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataItens?: any[];
  clearFilter?: number;
  initActive?: boolean;
}

export default function CheckFilterRadio({
  action,
  dataKey,
  dataDesc,
  dataItens = [],
  clearFilter,
  initActive = false,
}: Props) {
  const [active, setActive] = useState(initActive);
  // const [resp, setResp] = useState<string>('');
  const [value, setValue] = useState<string>(dataItens[0].id);

  useEffect(() => {
    if (active && value) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = new Object();
      obj[dataKey] = `'${value}'`;
      action({ ...obj });
    } else {
      action({});
    }
  }, [action, active, dataKey, value]);

  const lastCount = useRef(0);

  useEffect(() => {
    if (!active) return;
    if (!clearFilter) return;
    const current = lastCount.current;
    if (clearFilter !== current) {
      setActive(false);
      lastCount.current = clearFilter;
    }
  }, [active, clearFilter]);

  useEffect(() => {
    if (!clearFilter) return;
    lastCount.current = clearFilter;
  }, [clearFilter]);

  return (
    <div
      className={classNames(
        'flex flex-col space-y-2',
        'p-2 rounded-lg',
        active ? 'bg-slate-400 ' : 'bg-slate-200',
        'text-slate-900',
        active ? 'font-bold' : '',
      )}
    >
      <div className="flex items-center gap-2">
        <Checkbox
          id="check"
          defaultChecked={false}
          checked={active}
          onChange={() => setActive(!active)}
        />
        <Label htmlFor="check" className={classNames(active === false && 'text-slate-500')}>
          {dataDesc}
        </Label>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="w-full flex justify-between">
          {dataItens.map((el, i) => {
            return (
              <div
                key={i}
                className={classNames(
                  'flex items-center gap-2 text-slate-400',
                  active ? 'text-slate-700 ' : 'text-slate-300',
                )}
              >
                <Radio
                  id={dataKey}
                  checked={value === el.id}
                  onChange={() => setValue(el.id)}
                  disabled={!active}
                />
                <span className="text-xs">{el.desc}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

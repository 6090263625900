/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentMagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';

export function NotFoundPage() {
  const location = useLocation();

  return (
    <div className="grid max-w-screen-lg grid-cols-1 place-items-center gap-12 p-6 lg:grid-cols-2 lg:gap-24">
      <div className="absolute p-6 opacity-20 lg:static lg:opacity-100">
        <DocumentMagnifyingGlassIcon className="w-60 h-60 fill-secondary" />
      </div>
      <div className="z-2 text-center lg:text-left flex flex-col">
        <p className="mt-4 text-7xl font-bold text-secondary dark:text-accent lg:mt-0">404</p>
        <p className="mt-6 text-xl font-semibold text-slate-800 dark:text-navy-50 lg:mt-10 lg:text-3xl">
          Página não localizada.
        </p>
        <p className="pt-2 text-slate-500 dark:text-navy-200 lg:text-lg">
          Esta página não foi localizada!
        </p>
        <code className="bg-slate-300 py-3 w-full my-3 px-1 rounded-lg">
          {(location as any).pathname}
        </code>
      </div>
    </div>
  );
}

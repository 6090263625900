/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { usePopper } from 'react-popper';
import { NavLink } from 'react-router-dom';
import { AvatarComp } from '../Components/AvatarComp';
import { useAuth } from '../Auth/Context';
import { PATHS } from '../app.settings';

interface Props {
  image?: string;
  name: string;
  info?: string;
}

export function AppMenuUser({ image, name, info }: Props) {
  const { signOut } = useAuth();
  const [referenceElement, setReferenceElement] = useState({} as any);
  const [popperElement, setPopperElement] = useState({} as any);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 14] } }],
  });

  return (
    <div className="inline-block ">
      <Menu as="div" className="relative z-50 inline-block text-left">
        {({ open }) => (
          <>
            <Menu.Button
              ref={setReferenceElement}
              className={classNames('mx-3 flex items-center text-white text-sm z-50')}
            >
              <AvatarComp name={name} image={image} info={info} />
              <ChevronDownIcon
                className={classNames(
                  'ml-2 -mr-1 h-5 w-5 hover:font-bold',
                  open ? 'rotate-0' : '-rotate-180',
                )}
                aria-hidden="true"
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className={classNames(
                  'flex flex-col bg-slate-50',
                  'rounded-lg shadow-md w-40',
                  'divide-y',
                )}
              >
                <Menu.Item>
                  {({ active }) => (
                    <NavLink
                      className={({ isActive }) =>
                        classNames(
                          `${active && 'bg-gray-200'}`,
                          'px-4 py-2 text-sm',
                          isActive ? 'text-secondary' : 'text-primary',
                        )
                      }
                      to={PATHS.profile}
                    >
                      Meu Perfil
                    </NavLink>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        `${active && 'bg-gray-200'}`,
                        'text-primary text-start',
                        'px-4 py-2 text-sm',
                        'hover:text-secondary',
                      )}
                      onClick={signOut}
                    >
                      Logout
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

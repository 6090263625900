import { useLocation, Navigate } from 'react-router-dom';
import { PATHS } from '../app.settings';
import { useAuth } from './Context';

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { authenticated } = useAuth();
  const location = useLocation();

  if (!authenticated) {
    console.log(
      '🚀 ~ file: RequireAuth.tsx ~ line 10 ~ RequireAuth ~ authenticated',
      authenticated,
    );
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={PATHS.login} state={{ from: location }} replace />;
  }

  return children;
}

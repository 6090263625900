import { useState } from 'react';
import CheckFilterMunicipio from './checkFilterMunicipio';
import { useAssociado } from '../context';
import { Button } from 'flowbite-react';
import CheckFilterGeneric from './checkFilterGeneric';
import CheckFilterRadio from './checkFilterRadio';
import CheckFilterPeriod from './checkFilterPeriod';
import CheckFilterInput from './checkFilterInput';

export default function Filters() {
  const { setFilter, auxTables, sort, setSort, setSkip } = useAssociado();
  // console.log('🚀 ~ file: index.tsx:9 ~ Filters ~ auxTables', auxTables);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [count, setCount] = useState(0);

  const filter = () => {
    setFilter(filters);
    setSkip(0);
  };

  return (
    <div className="h-full">
      <div className="flex justify-between mb-3">
        <Button.Group>
          <Button size="xs" onClick={() => setSort(1)} title="Ascendente">
            <svg
              className={`h-4 w-4 ${sort === 1 ? 'fill-white' : 'fill-blue-800'}`}
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m8.656 5.778c0 .414.336.75.75.75h5.16c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-5.16c-.414 0-.75.336-.75.75zm-2.206 4c0 .414.336.75.75.75h9.596c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-9.596c-.414 0-.75.336-.75.75zm-2.45 4c0 .414.336.75.75.75h14.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75zm-2 4c0 .414.336.75.75.75h18.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75z"
                fillRule="nonzero"
              />
            </svg>
          </Button>
          <Button size="xs" onClick={() => setSort(-1)} title="Descendente">
            <svg
              className={`h-4 w-4 ${sort === -1 ? 'fill-white' : 'fill-blue-800'}`}
              clipRule="evenodd"
              fillRule="evenodd"
              strokeLinejoin="round"
              strokeMiterlimit="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m15.344 17.778c0-.414-.336-.75-.75-.75h-5.16c-.414 0-.75.336-.75.75s.336.75.75.75h5.16c.414 0 .75-.336.75-.75zm2.206-4c0-.414-.336-.75-.75-.75h-9.596c-.414 0-.75.336-.75.75s.336.75.75.75h9.596c.414 0 .75-.336.75-.75zm2.45-4c0-.414-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75h14.5c.414 0 .75-.336.75-.75zm2-4c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75z"
                fillRule="nonzero"
              />
            </svg>
          </Button>
        </Button.Group>
        <Button size="xs" onClick={filter}>
          Aplicar filtros
        </Button>
      </div>
      <div className="flex justify-end mb-2">
        <button
          onClick={() => {
            setCount(count + 1);
          }}
          className="text-slate-500 text-sm underline"
        >
          Limpar Filtros
        </button>
      </div>
      <div className="flex flex-col space-y-2 overflow-hidden overflow-y-auto h-[72vh]">
        <CheckFilterRadio
          dataKey={'filiado'}
          dataDesc={'Filiado'}
          dataItens={[
            {
              id: 's',
              desc: 'Sim',
            },
            {
              id: 'n',
              desc: 'Não',
            },
          ]}
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              // eslint-disable-next-line quotes
              filter.filiado = filter.filiado === "'s'" ? true : false;
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.filiado;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
          initActive={false}
        />

        <CheckFilterPeriod
          dataKey={'sindicatoData'}
          dataDesc={'Data de Filiação'}
          action={(filter) => {
            // console.log('🚀 ~ file: index.tsx:339 ~ Filters ~ filter', filter);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.sindicatoData;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterMunicipio
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.municipio;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'coordenadoriaId'}
          dataDesc={'Coordenadoria'}
          auxTable="Coordenadoria"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.coordenadoriaId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'lotacaoId'}
          dataDesc={'Lotação'}
          auxTable="Lotacao"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.lotacaoId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'mesoRegiaoId'}
          dataDesc={'Meso-Região'}
          auxTable="MesoRegiao"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.mesoRegiaoId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'microRegiaoId'}
          dataDesc={'Micro-Região'}
          auxTable="MicroRegiao"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.microRegiaoId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'concursoId'}
          dataDesc={'Concurso'}
          auxTable="Concurso"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.concursoId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'estadoCivilId'}
          dataDesc={'Estado Civil'}
          auxTable="EstadoCivil"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.estadoCivilId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'situacaoFuncionalId'}
          dataDesc={'Sintuação Funcional'}
          auxTable="SituacaoFuncional"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.situacaoFuncionalId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'vinculoFuncionalId'}
          dataDesc={'Vínculo Funcional'}
          auxTable="Vinculo"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.vinculoFuncionalId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'grauInstrucaoId'}
          dataDesc={'Grau de Instrução'}
          auxTable="GrauInstrucao"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.grauInstrucaoId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterGeneric
          dataKey={'interesseEducacionalId'}
          dataDesc={'Interesse Educacional'}
          auxTable="InteresseEducacional"
          action={(filter) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.interesseEducacionalId;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterRadio
          dataKey={'sexo'}
          dataDesc={'Sexo'}
          dataItens={[
            {
              id: 'F',
              desc: 'Feminino',
            },
            {
              id: 'M',
              desc: 'Masculino',
            },
          ]}
          action={(filter) => {
            // console.log('🚀 ~ file: index.tsx:339 ~ Filters ~ filter', filter);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.sexo;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterPeriod
          dataKey={'nascimento'}
          dataDesc={'Nascimento'}
          action={(filter) => {
            // console.log('🚀 ~ file: index.tsx:339 ~ Filters ~ filter', filter);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.nascimento;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterInput
          dataKey={'aniversario'}
          dataDesc={'Aniversário'}
          inputHint="Mês"
          minInputNumber={1}
          maxInputNumber={12}
          inputType="number"
          action={(filter) => {
            // console.log('🚀 ~ file: index.tsx:339 ~ Filters ~ filter', filter);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              filter.aniversario = Number(filter.aniversario);
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.aniversario;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />

        <CheckFilterPeriod
          dataKey={'exercicio'}
          dataDesc={'Exercício'}
          action={(filter) => {
            // console.log('🚀 ~ file: index.tsx:339 ~ Filters ~ filter', filter);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.exercicio;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />
        <CheckFilterPeriod
          dataKey={'created_at'}
          dataDesc={'Data do Cadastro'}
          action={(filter) => {
            // console.log('🚀 ~ file: index.tsx:339 ~ Filters ~ filter', filter);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.created_at;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />
        <CheckFilterPeriod
          dataKey={'updated_at'}
          dataDesc={'Data Alteração Cadastral'}
          action={(filter) => {
            // console.log('🚀 ~ file: index.tsx:339 ~ Filters ~ filter', filter);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let newFilter!: Record<string, any>;
            if (Object.keys(filter).length > 0) {
              newFilter = Object.assign(filters, filter);
            } else {
              newFilter = filters;
              delete newFilter.updated_at;
            }
            setFilters(newFilter);
          }}
          clearFilter={count}
        />
      </div>
    </div>
  );
}

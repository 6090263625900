import { useAssociado } from './context';
import {
  FunnelIcon,
  PlusCircleIcon,
  TableCellsIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';
import { Button, Pagination } from 'flowbite-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../app.settings';
import { BreadcrumbsComp } from '../../Components/BreadcrumbsComp';
import DrawerComp from '../../Components/DrawerComp';
import ExcelDownloadComp from '../../Components/ExcelDownloadComp';
// import FilterTextButtonComp from '../../Components/FilterTextButtonComp';
import LoadingPageComp from '../../Components/LoadingPageComp';
import Filters from './filters';
import FilterTextOptionButtonComp from '../../Components/FilterTextOptionButtonComp';
import { EXCEL_FIELDS } from './excelFields';
import ViewGrid from './viewGrid';
import ViewCard from './viewCard';
import classNames from 'classnames';

export default function AssociadoList() {
  const navigate = useNavigate();
  const {
    loading,
    list,
    listExcel,
    info,
    setFilter,
    showFilters,
    setShowFilters,
    viewMode,
    setViewMode,
    setSkip,
    setTake,
  } = useAssociado();

  const [search, _setSearch] = useState('');
  const [field, _setField] = useState('');

  const setSearch = (text: string, opt: string) => {
    _setSearch(text);
    _setField(opt);
    setSkip(0);
    setFilter({ [opt]: text });
  };

  const onPageChange = (page: number) => {
    setSkip(page - 1);
  };

  return (
    <>
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp title="Associados" paths={[]} />
        <div className="flex space-x-1 px-2">
          <button
            className={classNames(
              viewMode === 'card' ? 'bg-slate-400' : 'bg-slate-200',
              'rounded-lg p-1',
            )}
            type="button"
            onClick={() => setViewMode('card')}
            title="Visão Cartão"
          >
            <ViewColumnsIcon className="h-6 w-6 rotate-90 stroke-slate-500" />
          </button>
          <button
            className={classNames(
              viewMode === 'grid' ? 'bg-slate-400' : 'bg-slate-200',
              'rounded-lg p-1',
            )}
            type="button"
            onClick={() => setViewMode('grid')}
            title="Visão Grid"
          >
            <TableCellsIcon className="h-6 w-6 stroke-slate-500" />
          </button>
        </div>
        <div className="flex space-x-2 items-center w-full">
          <FilterTextOptionButtonComp
            value={search}
            onChange={setSearch}
            option={field}
            allOptions="searchFor"
            options={[
              {
                desc: 'Nome',
                value: 'nome',
              },
              {
                desc: 'Naturalidade',
                value: 'naturalidade',
              },
              {
                desc: 'Num RG',
                value: 'rgNro',
              },
              {
                desc: 'CPF',
                value: 'cpf',
              },
              {
                desc: 'Nome Pai',
                value: 'nomePai',
              },
              {
                desc: 'Nome Mãe',
                value: 'nomeMae',
              },
              {
                desc: 'Email Inst.',
                value: 'emailInstitucional',
              },
              {
                desc: 'Email Pesoal',
                value: 'email',
              },
              {
                desc: 'Cidade',
                value: 'cidade',
              },
              {
                desc: 'CEP',
                value: 'cep',
              },
              {
                desc: 'CEL 1',
                value: 'cel1',
              },
              {
                desc: 'CEL 2',
                value: 'cel2',
              },
              {
                desc: 'CEL 3',
                value: 'cel3',
              },
              {
                desc: 'FIXO',
                value: 'fixo',
              },
              {
                desc: 'Matrícula',
                value: 'matricula',
              },
              {
                desc: 'Cargo',
                value: 'cargo',
              },
              {
                desc: 'Função',
                value: 'funcao',
              },
              {
                desc: 'Instituição',
                value: 'instituicao',
              },
              {
                desc: 'Lotação',
                value: 'lotacao',
              },
              {
                desc: 'Especialidade',
                value: 'especialidade',
              },
              {
                desc: 'Obs.',
                value: 'obs',
              },
            ]}
          />
          <Button
            outline={showFilters}
            color={'primary'}
            size="xs"
            onClick={() => setShowFilters(!showFilters)}
          >
            <div className="flex">
              <div className="mr-3">
                <FunnelIcon className="w-4 h-4" />
              </div>
              <span>Filtros</span>{' '}
            </div>
          </Button>
          <ExcelDownloadComp caption="Excel" data={listExcel} fields={EXCEL_FIELDS} />
          <Button color="secondary" size="xs" onClick={() => navigate(PATHS.associadoInsert)}>
            <div className="mr-3">
              <PlusCircleIcon className="w-4 h-4" />
            </div>
            Inserir
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-end pb-3"></div>
      <div className="hidden md:block h-[70vh] overflow-hidden overflow-y-auto">
        {loading ? (
          <LoadingPageComp />
        ) : (
          <>
            {viewMode === 'card' && <ViewCard list={list} />}
            {viewMode === 'grid' && <ViewGrid list={list} />}
          </>
        )}
      </div>
      <div className="flex justify-between">
        <Pagination
          showIcons={true}
          layout="pagination"
          currentPage={parseInt(info?.pagina ?? '0') + 1}
          totalPages={
            info?.registros ? Math.ceil(info?.registros / Number(info?.qtdePorPagina ?? 0)) : 0
          }
          onPageChange={onPageChange}
          nextLabel="Próximo"
          previousLabel="Anterior"
        />
        <div className="flex items-center space-x-2">
          <span className="text-sm">
            Qtde por página:{' '}
            <select
              className="text-xs rounded-md border border-gray-300"
              value={info?.qtdePorPagina ?? 10}
              onChange={(e) => {
                setTake(Number(e.target.value));
                setSkip(0);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </span>
          <span className="text-sm">Total de registros: {info?.registros}</span>
        </div>
      </div>

      <DrawerComp
        show={showFilters}
        position="left"
        close={() => setShowFilters(!showFilters)}
        showButtonClose={false}
      >
        <div className="text-black">
          <Filters />
        </div>
      </DrawerComp>
    </>
  );
}

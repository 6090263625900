import { useRef, useState, useEffect } from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import { IAssociado } from './model';
import AvatarEditor from 'react-avatar-editor';
import { Button, FileInput, Label, RangeSlider } from 'flowbite-react';
import Avatar from '../../assets/png/avatar.png';
import { upload } from './service';
import LoadingComp from '../../Components/LoadingComp';
import { useAssociado } from './context';

interface Props {
  register?: UseFormRegister<IAssociado>;
  watch: UseFormWatch<IAssociado>;
  img?: string;
}

export default function FormAssociadoImagem({ watch, img }: Props) {
  const { uplodImg } = useAssociado();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editor = useRef<any>(null);

  const id = watch('id');
  const [image, setImage] = useState<string | undefined>(undefined);
  const [preview, setPreview] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setImage(reader.result as string);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }, [file]);

  useEffect(() => {
    if (!img) {
      return;
    }
    setImage(img);
  }, [img]);

  const previewImg = () => {
    if (editor && editor.current) {
      const canvas = editor.current.getImage();
      setPreview(canvas.toDataURL());
    }
  };

  // function blobToFile(theBlob: Blob, fileName: string): File {
  //   const b: any = theBlob;
  //   b.lastModifiedDate = new Date();
  //   b.name = fileName;
  //   return theBlob as File;
  // }

  // const uplodImg = async () => {
  //   if (!file || !id) return;
  //   const dataUrl = editor.current.getImage().toDataURL();
  //   const result = await fetch(dataUrl);
  //   const blob = await result.blob();
  //   setUploading(true);

  //   upload(String(id), blobToFile(blob, 'upload-associado'), '1024', '1024', (progress) => {
  //     // console.log('🚀 ~ file: formImagem.tsx:72 ~ uplodImg ~ progress', progress * 100);
  //   })
  //     .then(() => {
  //       setImage(result.url);
  //       setRotate(0);
  //       setScale(1);
  //     })
  //     .catch((e) => {
  //       console.log('🚀 ~ Upload Error', e);
  //       window.location.reload();
  //     })
  //     .finally(() => {
  //       setUploading(false);
  //       afterUploadAction();
  //     });
  // };

  const doUpload = async () => {
    if (!file || !id) return;
    setUploading(true);
    uplodImg(file, id, editor.current.getImage().toDataURL(), () => {
      setRotate(0);
      setScale(1);
      setUploading(false);
    });
  };

  if (uploading) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <LoadingComp />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* linha 1 */}
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-3">
          {image ? (
            <AvatarEditor
              className="w-full mx-auto"
              ref={editor}
              image={image}
              border={10}
              color={[0, 0, 0, 0.1]} // RGBA
              scale={scale}
              rotate={rotate}
            />
          ) : (
            <LoadingComp />
          )}
        </div>
        <div className="col-span-6">
          <div id="fileUpload">
            <div className="mb-2 block">
              <Label htmlFor="file" value="Enviar uma imagem" />
            </div>
            <FileInput
              id="file"
              helperText="Selecione uma imagem do seu computador, você pode mover, rotacionar, aumentar e diminuir. A imagem que será enviada é o que esta dentro da moldura."
              onChange={(e) => {
                const files = e.target?.files;
                if (files && files.length > 0) {
                  setFile(files[0]);
                }
              }}
            />
          </div>
          <div>
            <div className="mb-1 block">
              <Label htmlFor="default-range" value={'Zoom (' + Math.round(scale * 100) + '%)'} />
            </div>
            <RangeSlider
              id="default-range"
              onChange={(e) => setScale(Number(e.target.value))}
              value={scale}
              min={0}
              step={0.1}
              max={2}
            />
          </div>
          <div>
            <div className="mb-1 block">
              <Label htmlFor="default-range2" value={'Rotacionar'} />
            </div>
            <RangeSlider
              id="default-range2"
              onChange={(e) => setRotate(Number(e.target.value))}
              value={rotate}
              min={0}
              step={10}
              max={360}
            />
          </div>
          {/* <div>
            <div className="mb-1 block">
              <Label htmlFor="default-range3" value={'Upload'} />
            </div>
            <RangeSlider
              id="default-range3"
              onChange={(e) => setRotate(Number(e.target.value))}
              value={progress}
              min={0}
              step={1}
              max={100}
            />
          </div> */}
        </div>
        <div className="col-span-3 space-y-2">
          <div className="flex justify-between">
            <Button size={'sm'} onClick={previewImg}>
              Pre-visualizar
            </Button>
            {file && (
              <Button onClick={doUpload} size={'sm'} color={'success'}>
                Enviar
              </Button>
            )}
          </div>
          <div className="border-dotted border-2 border-primary">
            <img src={preview || Avatar} className="w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  );
}

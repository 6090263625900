import classNames from 'classnames';
import { ReactNode } from 'react';

interface Props {
  show: boolean;
  close: () => void;
  position: 'right' | 'left';
  children: ReactNode;
  showButtonClose?: boolean;
}

export default function DrawerComp({
  show,
  close,
  position,
  children,
  showButtonClose = false,
}: Props) {
  if (position === 'left')
    return (
      <div
        id="readProductDrawer"
        className={classNames(
          'drop-shadow-lg overflow-y-auto fixed top-0 left-0 z-40 p-4 w-full max-w-xs h-screen bg-white transition-transform dark:bg-gray-800',
          show ? 'translate-x-0' : '-translate-x-full',
        )}
        tabIndex={-1}
      >
        <div className="flex justify-end mt-10">
          <button
            type="button"
            onClick={close}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close menu</span>
          </button>
        </div>
        {children}
      </div>
    );
  else
    return (
      <div
        id="readProductDrawer"
        className={classNames(
          'drop-shadow-lg overflow-y-auto fixed top-0 left-full z-40 p-4 w-full max-w-xs h-screen bg-white transition-transform dark:bg-gray-800',
          show ? '-translate-x-full' : 'translate-x-0',
        )}
        tabIndex={-1}
      >
        {showButtonClose && (
          <div className="flex justify-end mt-10">
            <button
              type="button"
              onClick={close}
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close menu</span>
            </button>
          </div>
        )}
        {children}
      </div>
    );
}

import classNames from 'classnames';
import { useEffect, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../app.settings';
import { useApp } from '../../../AppContext';
import { BreadcrumbsComp } from '../../../Components/BreadcrumbsComp';
import { ButtonBackComp } from '../../../Components/ButtonBackComp';
import { ButtonComp } from '../../../Components/ButtonComp';
import { ButtonDeleteComp } from '../../../Components/ButtonDeleteComp';
import ComboboxComp, { IComboboxData } from '../../../Components/ComboboxComp';
import LoadingPageComp from '../../../Components/LoadingPageComp';
import CoordenadoriaComboboxComp from '../Coordenadoria/ComboboxComp';
import MesoRegiaoComboboxComp from '../MesoRegiao/ComboboxComp';
import MicroRegiaoComboboxComp from '../MicroRegiao/ComboboxComp';
import { useComarca } from './context';
import { IComarca } from './model';
import { save, remove } from './service';

export default function ComarcaForm() {
  const { current, loading } = useComarca();
  const { ufs: _ufs } = useApp();
  const [ufs, setUfs] = useState<IComboboxData[]>([]);
  const navigate = useNavigate();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<IComarca>();

  const loadForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (record: Record<string, any>) => {
      if (!record) return;
      const entries = Object.entries(record);
      entries.forEach(([key, value]) => {
        switch (key) {
          case 'microRegiao':
          case 'mesoRegiao':
          case 'coordenadoria': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(key as any, value.id);
            break;
          }

          default:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(key as any, value);
        }
      });
    },
    [setValue],
  );

  useEffect(() => {
    const map = _ufs.map((d) => ({
      id: d.uf,
      desc: d.descricao,
      isVisible: true,
    }));
    setUfs(map);

    if (current?.ibge) {
      loadForm(current);
    } else {
      setValue('isVisible', true);
    }
  }, [_ufs, current, loadForm, setValue]);

  const navigateToList = async (sleep: number) => {
    await new Promise((t) => setTimeout(t, sleep)).then(() => {
      navigate(PATHS.comarca);
    });
  };

  const submit = (data: IComarca) => {
    data.ordenacao = data.ordenacao ? Number(data.ordenacao) : 0;
    // eslint-disable-next-line camelcase
    data.updated_at = new Date();
    save(data).then(loadForm);
    navigateToList(1000);
  };

  const del = () => {
    const id = getValues('ibge').toString();
    if (id) {
      remove(id).then(loadForm);
      navigateToList(1000);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="divide-y space-y-4">
      <div className="flex items-center justify-between py-3">
        <BreadcrumbsComp
          title={current?.ibge ? 'Alterar' : 'Inserir'}
          paths={[
            {
              label: 'Municípios',
              onClick: () => navigateToList(0),
            },
          ]}
        />
        <div className="flex space-x-2">
          <ButtonBackComp label="Voltar" type="button" onClick={() => navigateToList(0)} />
          {current?.ibge && (
            <ButtonDeleteComp confirm={true} label="Excluir" type="button" onClick={del} />
          )}
          <ButtonComp label="Salvar" type="submit" />
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingPageComp />
        ) : (
          <div className="max-w-2xl space-y-2">
            <div className="flex justify-between space-x-2">
              <label htmlFor="nome" className="flex flex-col w-full">
                <span>Nome</span>
                <input
                  id="nome"
                  type="text"
                  className="rounded-lg"
                  readOnly={true}
                  placeholder="Nome"
                  {...register('nome', {
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.nome && (
                  <span className="text-red-500 text-xs">{errors?.nome?.message}</span>
                )}
              </label>
              <label htmlFor="uf" className="flex flex-col z-auto relative">
                <span>UF</span>
                <Controller
                  control={control}
                  name={'uf'}
                  rules={{
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  }}
                  render={({ field }) => (
                    <ComboboxComp
                      disabled={true}
                      value={field.value}
                      onChange={field.onChange}
                      data={ufs}
                    />
                  )}
                />

                {errors?.coordenadoria && (
                  <span className="text-red-500 text-xs">{errors?.coordenadoria?.message}</span>
                )}
              </label>
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="coordenadoria" className="flex flex-col z-50 relative">
                <span>Coordeandoria</span>
                <Controller
                  control={control}
                  name={'coordenadoria'}
                  rules={{
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  }}
                  render={({ field }) => (
                    <CoordenadoriaComboboxComp
                      value={field.value as unknown as number}
                      onChange={field.onChange}
                    />
                  )}
                />

                {errors?.coordenadoria && (
                  <span className="text-red-500 text-xs">{errors?.coordenadoria?.message}</span>
                )}
              </label>

              <label htmlFor="mesoRegiao" className="flex flex-col z-40 relative w-full">
                <span>Mesoregião</span>
                <Controller
                  control={control}
                  name={'mesoRegiao'}
                  rules={{
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  }}
                  render={({ field }) => (
                    <MesoRegiaoComboboxComp
                      value={field.value as unknown as number}
                      onChange={field.onChange}
                    />
                  )}
                />

                {errors?.mesoRegiao && (
                  <span className="text-red-500 text-xs">{errors?.mesoRegiao?.message}</span>
                )}
              </label>

              <label htmlFor="microRegiao" className="flex flex-col z-30 relative">
                <span>Microregião</span>
                <Controller
                  control={control}
                  name={'microRegiao'}
                  rules={{
                    required: {
                      value: true,
                      message: '(*) Campo requerido',
                    },
                  }}
                  render={({ field }) => (
                    <MicroRegiaoComboboxComp
                      value={field.value as unknown as number}
                      onChange={field.onChange}
                    />
                  )}
                />

                {errors?.microRegiao && (
                  <span className="text-red-500 text-xs">{errors?.microRegiao?.message}</span>
                )}
              </label>
            </div>

            <div className="flex justify-between items-end space-x-2">
              <label htmlFor="adhoc" className="flex flex-col">
                <span>ADHOC</span>
                <textarea
                  id="adhoc"
                  className="rounded-lg form-input"
                  placeholder="ADHOC"
                  {...register('adhoc', {
                    required: {
                      value: false,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.adhoc && (
                  <span className="text-red-500 text-xs">{errors?.adhoc?.message}</span>
                )}
              </label>
              <label htmlFor="equipamentos" className="flex flex-col w-full">
                <span>Equipamentos</span>
                <textarea
                  id="equipamentos"
                  className="rounded-lg form-input"
                  placeholder="Equipamentos"
                  {...register('equipamentos', {
                    required: {
                      value: false,
                      message: '(*) Campo requerido',
                    },
                  })}
                />
                {errors?.equipamentos && (
                  <span className="text-red-500 text-xs">{errors?.equipamentos?.message}</span>
                )}
              </label>
            </div>

            <div className="flex justify-between items-end">
              <label htmlFor="isSede" className="flex flex-col z-0 relative">
                <span>É Sede?</span>
                <span className="inline-flex items-center space-x-3">
                  <input
                    id="isSede"
                    type="checkbox"
                    className={classNames(
                      'form-switch h-5 w-10 rounded-full',
                      'before:rounded-full before:bg-slate-50 ',
                      'bg-slate-300 checked:bg-primary checked:before:bg-white',
                      'dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white',
                    )}
                    {...register('isSede')}
                  />
                  <span>{watch('isSede', false) ? 'Sim' : 'Não'}</span>
                </span>
              </label>

              <label htmlFor="isCentralComando" className="flex flex-col z-0 relative">
                <span>É Comando Central?</span>
                <span className="inline-flex items-center space-x-3">
                  <input
                    id="isCentralComando"
                    type="checkbox"
                    className={classNames(
                      'form-switch h-5 w-10 rounded-full',
                      'before:rounded-full before:bg-slate-50 ',
                      'bg-slate-300 checked:bg-primary checked:before:bg-white',
                      'dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white',
                    )}
                    {...register('isCentralComando')}
                  />
                  <span>{watch('isCentralComando', false) ? 'Sim' : 'Não'}</span>
                </span>
              </label>

              {/* <label htmlFor="isVisible" className="inline-flex items-center space-x-3">
                <input
                  id="isVisible"
                  type="checkbox"
                  className={classNames(
                    'form-switch h-5 w-10 rounded-full',
                    'before:rounded-full before:bg-slate-50 ',
                    'bg-slate-300 checked:bg-primary checked:before:bg-white',
                    'dark:bg-navy-900 dark:before:bg-navy-300 dark:checked:bg-accent dark:checked:before:bg-white',
                  )}
                  {...register('isVisible')}
                />
                <span>{watch('isVisible', false) ? 'Ativo' : 'Inativo'}</span>
              </label> */}

              {/* <label htmlFor="ordenacao" className="inline-flex items-center space-x-3">
                <input
                  id="ordenacao"
                  type="number"
                  className="rounded-lg text-sm w-16"
                  {...register('ordenacao')}
                />
                <span>Ordenação</span>
              </label> */}
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

import { ChevronDoubleLeftIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
}

export function ButtonBackComp({ label, ...rest }: Props) {
  return (
    <button
      className={classNames(
        'inline-flex space-x-2 w-full items-center justify-center rounded-xl px-4 py-2 text-base font-medium shadow-sm',
        'border border-gray-300',
        'bg-slate-300 text-primary',
        'hover:bg-slate-300/70',
        'focus:outline-none focus:ring-2 focus:ring-slate/50 focus:ring-offset-2',
        'disabled:bg-slate-400',
        'h-8 sm:w-auto sm:text-sm',
      )}
      {...rest}
    >
      <ChevronDoubleLeftIcon className="w-5 h-5" />
      <span>{label}</span>
    </button>
  );
}

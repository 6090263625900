import { useEffect, useState } from 'react';
import PaginateComp from './Paginate';

export interface ICol {
  key: string;
  desc: string | number;
}
[];

export interface IRow {
  rowId: string;
  tds: {
    key: string;
    value: string | number;
  }[];
}

interface Props {
  cols: ICol[];
  rows: IRow[];
  perPage?: number;
  currentPage?: number;
  action?: (row: string, currentPage: number) => void;
}

export function TableComp({ cols, rows, action, perPage, currentPage }: Props) {
  const [paginated, setPaginated] = useState<IRow[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState<number>(0);

  useEffect(() => {
    if (perPage) {
      const ranger = rows.slice(selectedPage * perPage, selectedPage * perPage + perPage);
      setPaginated(ranger);
    } else {
      setPaginated(rows);
    }
  }, [perPage, rows, selectedPage]);

  useEffect(() => {
    if (perPage) setPageCount(Math.ceil(rows.length / perPage));
  }, [perPage, rows.length]);

  useEffect(() => {
    if (currentPage) setSelectedPage(currentPage);
  }, [currentPage]);

  const listKeyCols = () => {
    const list: string[] = [];
    cols.map((col) => {
      list.push(col.key);
    });
    return list;
  };
  return (
    <>
      <table className="w-full rounded-t-lg text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs rounded-t-lg text-primary uppercase bg-slate-200">
          <tr>
            {cols.map((col, colIdx) => (
              <th key={colIdx} scope="col" className="py-2 px-2">
                {col.desc}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginated.map((row, rowIdx) => (
            <tr
              key={rowIdx}
              className="bg-white border-b hover:bg-slate-50 hover:border-slate-100 cursor-pointer"
              onClick={() => {
                action && action(row.rowId, selectedPage);
              }}
            >
              {row.tds.map((td, tdIdx) => {
                if (listKeyCols().includes(td.key)) {
                  return (
                    <th
                      key={tdIdx}
                      scope="row"
                      className="py-4 px-2 font-medium text-gray-900 whitespace-nowrap hover:font-bold"
                    >
                      {td.value}
                    </th>
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="my-3">
        {perPage && rows.length > perPage && (
          <PaginateComp
            onPageChange={({ selected }) => setSelectedPage(selected)}
            pageCount={pageCount}
            page={selectedPage + 1}
          />
        )}
      </div>
    </>
  );
}

import { Table } from 'flowbite-react';

import NoResultsPNG from '../../assets/png/no-results.png';
import NoImagePNG from '../../assets/png/no-image.png';
import { IAssociado } from './model';
import { useNavigate } from 'react-router-dom';
import { PATHS, getImageAssociadoPath } from '../../app.settings';
import LazyLoadImageComp from '../../Components/LazyLoadImageComp';
import { FormattedDate } from 'react-intl';

interface Props {
  list: IAssociado[];
}

export default function ViewCard({ list }: Props) {
  const navigate = useNavigate();
  return (
    <Table hoverable={true}>
      {list.length === 0 ? (
        <div className="w-full">
          <img className="mx-auto grayscale-0 opacity-50" src={NoResultsPNG} alt="" />
        </div>
      ) : (
        <>
          <Table.Head style={{ position: 'sticky', top: 0 }}>
            <Table.HeadCell className="grow">Foto</Table.HeadCell>
            <Table.HeadCell className="grow">Nome</Table.HeadCell>
            <Table.HeadCell className="grow">Documentos</Table.HeadCell>
            <Table.HeadCell className="grow">Endereço</Table.HeadCell>
            <Table.HeadCell className="grow">Contato</Table.HeadCell>
            <Table.HeadCell className="grow">Situação</Table.HeadCell>
          </Table.Head>

          <Table.Body className="divide-y">
            {list
              .map((l) => ({
                ...l,
              }))
              .map((el) => (
                <Table.Row
                  key={el.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800 cursor-pointer"
                  onClick={() => navigate(PATHS.associadoEdit(el.id.toString()))}
                >
                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                    <LazyLoadImageComp
                      src={
                        el.images && el.images.length > 0
                          ? getImageAssociadoPath(el?.images[0])
                          : NoImagePNG
                      }
                      alt={el.nome}
                      height={120}
                      width={120}
                    />
                    {/* <img className="w-24 h-18" src={NoImagePNG} alt="Sem Imagem" /> */}
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                    <div className="">
                      <div className="font-bold text-md">{el.nome}</div>
                      <div className="text-xs">Mat: {el.matricula}</div>
                      <div className="text-xs">
                        Filiado: {el?.sindicatoData ? new Date(el.sindicatoData.toString().replace(/-/g, '/')).toLocaleDateString('pt-br'): ''}
                      </div>
                      <div className="text-xs font-bold ">
                        {el.filiado ? (
                          <span className="text-primary">FILIADO</span>
                        ) : (
                          <span className="text-red-600">NÃO FILIADO</span>
                        )}
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                    <div className="">
                      <div className="font-bold text-md">CPF: {el.cpf}</div>
                      <div className="text-xs">RG: {el.rgNro}</div>

                      <div className="text-xs">
                        Nascimento: {el?.nascimento ? <FormattedDate value={new Date(el.nascimento.replace(/-/g, '/'))} /> : ''}
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                    <div className="">
                      <div className="text-xs font-bold">
                        {el.cidade} - {el.uf}
                      </div>
                      <div className="text-xs">{el.endereco}</div>
                      <div className="text-xs">Bairro: {el.bairro}</div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                    <div className="">
                      <div className="text-xs font-bold">{el.cel1}</div>
                      <div className="text-xs">{el.email}</div>
                      <div className="text-xs">{el.emailInstitucional}</div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900 dark:text-white">
                    <div className="">
                      <div className="text-xs font-bold">
                        Situação: {el.situacaoFuncional?.descricao}
                      </div>
                      <div className="text-xs">Função: {el.funcao}</div>
                      <div className="text-xs">Vínculo: {el.vinculoFuncional?.descricao}</div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </>
      )}
    </Table>
  );
}

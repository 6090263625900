import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { ILotacao } from './model';
import { getAll } from './service';

interface IContext {
  loading: boolean;
  list: ILotacao[];
  current: ILotacao | undefined;
  setCurrent: (current: ILotacao) => void;
  setCurrentById: (current: number) => void;
  filter: string | undefined;
  setFilter: (uf: string | undefined) => void;
}

interface IProvider {
  children: React.ReactNode;
}

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ILotacao[]>([]);
  const [list, setList] = useState<ILotacao[]>([]);
  const [current, setCurrent] = useState<ILotacao>();
  const [filter, setFilter] = useState<string>();

  useEffect(() => {
    setLoading(true);
    getAll()
      .then((data) => data && setData(data))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    const filtered = data.filter((d) => {
      if (!filter) return false;

      return (
        d.comarca.toLowerCase().includes(filter.toLowerCase()) ||
        d.local.toLowerCase().includes(filter.toLowerCase()) ||
        d.municipio.nome.toLowerCase().includes(filter.toLowerCase())
      );
    });
    setList(filtered);
  }, [filter, data]);

  const setCurrentById = useCallback(async (id: number) => {
    setLoading(true);
    const data = await getAll();
    if (data) {
      setCurrent(data.find((d) => d.id === id));
    }
    setLoading(false);
  }, []);

  return (
    <Context.Provider
      value={{
        loading,
        list,
        current,
        setCurrent,
        filter,
        setFilter,
        setCurrentById,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useLotacao = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useLotacao, Provider as LotacaoProvider };

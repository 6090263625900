/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { InfoNotification } from '../../../app.notification';
import { AxiosErrorResponse } from '../../../app.settings';

import api from '../../../axios';
import { IMesoRegiao } from './model';

export async function getById(usuarioId: string): Promise<IMesoRegiao | any> {
  try {
    const { data } = await api.get<AxiosResponse<IMesoRegiao>>('/meso_regiao/' + usuarioId);
    return data as unknown as IMesoRegiao;
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}
export async function getAll(): Promise<IMesoRegiao[] | undefined> {
  try {
    const { data } = await api.get<AxiosResponse<IMesoRegiao[]>>('/meso_regiao');
    return data as unknown as IMesoRegiao[];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function update(usuario: Partial<IMesoRegiao>): Promise<IMesoRegiao | any> {
  try {
    const { data } = await api.put<AxiosResponse<IMesoRegiao>>('/meso_regiao/' + usuario.id, {
      ...usuario,
    });
    return (data as unknown as IMesoRegiao[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function insert(usuario: IMesoRegiao): Promise<IMesoRegiao | any> {
  try {
    const { data } = await api.post<AxiosResponse<IMesoRegiao>>('/meso_regiao/', {
      ...usuario,
    });
    return (data as unknown as IMesoRegiao[])[0];
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function save(usuario: IMesoRegiao): Promise<IMesoRegiao | any> {
  try {
    if (usuario.id) {
      return await update(usuario).then(() => {
        InfoNotification('Registro alterado!');
      });
    } else {
      return await insert(usuario).then(() => {
        InfoNotification('Registro criado!');
      });
    }
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

export async function remove(usuarioId: string): Promise<IMesoRegiao | any> {
  try {
    return await api.delete<AxiosResponse<IMesoRegiao>>('/meso_regiao/' + usuarioId).then(() => {
      InfoNotification('Registro excluido!');
    });
  } catch (error: any) {
    AxiosErrorResponse(error);
  }
}

import axios from 'axios';
import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IAuth, IToken } from '../app.models';
import {
  API_URL,
  AxiosErrorResponse,
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_RJWT,
  PATHS,
} from '../app.settings';
import { IUsuario } from '../Pages/Config/Usuario/model';
import { UpdatePassword, getById, updPassword } from '../Pages/Config/Usuario/service';
import { ErrorNotification, SuccessNotification } from '../app.notification';

interface IContext {
  authenticated: boolean;
  token: IToken;
  currentUser: IUsuario | undefined;
  setCurrentUser: (usuario: IUsuario | undefined) => void;
  updatePassword: (newPassword: string) => void;
  loading: boolean;
  signIn: (user: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

interface IProvider {
  children: React.ReactNode;
}
const api = axios.create({
  baseURL: API_URL,
});

const getJwt = (): IToken | undefined => {
  const token = localStorage.getItem(LOCAL_STORAGE_JWT);
  if (!token) return;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

const Context = createContext<IContext>({} as IContext);

const Provider: React.FC<IProvider> = ({ children }: IProvider) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState<boolean>(
    !!localStorage.getItem(LOCAL_STORAGE_JWT),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUsuario>();
  const token = getJwt() as IToken;

  const getToken = useCallback(() => {
    if (token) {
      getById(token.usuarioId).then(setCurrentUser);
    }
  }, [token]);

  useEffect(() => {
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (email: string, senha: string): Promise<void> => {
    const url = '/auth/login';
    setLoading(true);
    try {
      const ret = await api.post<IAuth>(url, {
        email,
        senha,
      });
      if (ret) {
        localStorage.setItem(LOCAL_STORAGE_JWT, ret.data.accessToken);
        localStorage.setItem(LOCAL_STORAGE_RJWT, ret.data.refreshToken);
        setAuthenticated(true);
        navigate(PATHS.dashboard);
        setCurrentUser(ret.data.usuario);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      AxiosErrorResponse(error);
    } finally {
      setLoading(false);
    }
  };

  const signOut = async () => {
    localStorage.removeItem(LOCAL_STORAGE_JWT);
    localStorage.removeItem(LOCAL_STORAGE_RJWT);
    setAuthenticated(false);
    navigate(PATHS.login);
  };

  const updatePassword = async (newPassword: string) => {
    if (!currentUser) return;
    const data: UpdatePassword = {
      id: String(currentUser.id),
      email: currentUser.email,
      senha: newPassword,
      perfil: currentUser.perfil,
    };
    if (!data) return;
    // console.log('🚀 ~ file: index.tsx:54 ~ updatePassword ~ data:', data);
    updPassword(data)
      ?.then(() => {
        const userId = String(currentUser.id);
        getById(userId).then((data) => {
          setCurrentUser(data);
          SuccessNotification('Senha alterada com sucesso');
        });
      })
      .catch((err) => {
        console.log('🚀 ~ err:', err);
        ErrorNotification('Erro ao alterar senha');
        setCurrentUser(currentUser);
      });
  };

  return (
    <Context.Provider
      value={{
        authenticated,
        token,
        currentUser,
        setCurrentUser,
        updatePassword,
        loading,
        signIn,
        signOut,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useAuth = (): IContext => {
  const context = useContext(Context);
  return context;
};

export { useAuth, Provider as AuthProvider };

import { RequireAdmin } from '../../../Auth/RequireAdmin';
import UsuarioList from './list';

export default function UsuarioPage() {
  return (
    <RequireAdmin>
      <UsuarioList />
    </RequireAdmin>
  );
}

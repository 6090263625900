import { Routes, Route } from 'react-router-dom';
import { AppProvider } from '../AppContext';
import { PATHS } from '../app.settings';
import { AuthProvider } from '../Auth/Context';
import { RequireAuth } from '../Auth/RequireAuth';
import { Layout } from '../Layout';
import { LayoutProvider } from '../Layout/Context';
import { Dashboard } from '../Pages/Dashboard';
import { Profile } from '../Pages/Profile';
import { Login } from '../Pages/Login';
import { ScapeAuth } from '../Auth/ScapeAuth';
import EstadoCivilPage from '../Pages/Cad/EstadoCivil';
import EstadoCivilForm from '../Pages/Cad/EstadoCivil/form';
import GrauInstrucaoPage from '../Pages/Cad/GrauInstrucao';
import GrauInstrucaoForm from '../Pages/Cad/GrauInstrucao/form';
import OrgaoEmissorForm from '../Pages/Cad/OrgaoEmissor/form';
import OrgaoEmissorPage from '../Pages/Cad/OrgaoEmissor';
import SituacaoFuncionalForm from '../Pages/Cad/SituacaoFuncional/form';
import SituacaoFuncionalPage from '../Pages/Cad/SituacaoFuncional';
import InteresseEducacionalPage from '../Pages/Cad/InteresseEducacional';
import InteresseEducacionalForm from '../Pages/Cad/InteresseEducacional/form';
import CoordenadoriaPage from '../Pages/Cad/Coordenadoria';
import CoordenadoriaForm from '../Pages/Cad/Coordenadoria/form';
import ConcursoPage from '../Pages/Cad/Concurso';
import ConcursoForm from '../Pages/Cad/Concurso/form';
import MesoRegiaoPage from '../Pages/Cad/MesoRegiao';
import MesoRegiaoForm from '../Pages/Cad/MesoRegiao/form';
import MicroRegiaoForm from '../Pages/Cad/MicroRegiao/form';
import MicroRegiaoPage from '../Pages/Cad/MicroRegiao';
import ContatoForm from '../Pages/Cad/Contato/form';
import ContatoPage from '../Pages/Cad/Contato';
import VinculoForm from '../Pages/Cad/Vinculo/form';
import VinculoPage from '../Pages/Cad/Vinculo';
import UsuarioPage from '../Pages/Config/Usuario';
import UsuarioForm from '../Pages/Config/Usuario/form';
import { UnauthorizedPage } from '../Auth/Unauthorized';
import { NotFoundPage } from '../Pages/NotFound';
import ComarcaPage from '../Pages/Cad/Comarcas';
import ComarcaForm from '../Pages/Cad/Comarcas/form';
import AssociadoPage from '../Pages/Associado';
import AssociadoForm from '../Pages/Associado/form';
import LotacaoPage from '../Pages/Cad/Lotacao';
import LotacaoForm from '../Pages/Cad/Lotacao/form';
import { AssociadoProvider } from '../Pages/Associado/context';
import { ComarcaProvider } from '../Pages/Cad/Comarcas/context';
import { LotacaoProvider } from '../Pages/Cad/Lotacao/context';
import {IntlProvider} from 'react-intl';

export function AppRoutes() {
  return (
    <IntlProvider locale={'pt-br'}>
      <AuthProvider>
        <AppProvider>
          <LayoutProvider>

              <Routes>
                {/* login route */}
                <Route
                  path={PATHS.login}
                  element={
                    <ScapeAuth>
                      <Login />
                    </ScapeAuth>
                  }
                />

                  {/* app routes */}
                  <Route element={<AssociadoProvider><Layout /></AssociadoProvider>}>
                    <Route
                      index
                      path={PATHS.dashboard}
                      element={
                        <RequireAuth>
                          <Dashboard />
                        </RequireAuth>
                      }
                    />

                    {/* not found */}
                    <Route path={PATHS.notFound} element={<NotFoundPage />} />

                    <Route
                      path={PATHS.profile}
                      element={
                        <RequireAuth>
                          <Profile />
                        </RequireAuth>
                      }
                    />
                    {/* estado civil */}
                    <Route
                      path={PATHS.estadoCivil}
                      element={
                        <RequireAuth>
                          <EstadoCivilPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.estadoCivilInsert}
                      element={
                        <RequireAuth>
                          <EstadoCivilForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.estadoCivilEdit()}
                      element={
                        <RequireAuth>
                          <EstadoCivilForm />
                        </RequireAuth>
                      }
                    />

                    {/* grau instrucao */}
                    <Route
                      path={PATHS.grauInstrucao}
                      element={
                        <RequireAuth>
                          <GrauInstrucaoPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.grauInstrucaoInsert}
                      element={
                        <RequireAuth>
                          <GrauInstrucaoForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.grauInstrucaoEdit()}
                      element={
                        <RequireAuth>
                          <GrauInstrucaoForm />
                        </RequireAuth>
                      }
                    />
                    {/* orgao emissor */}
                    <Route
                      path={PATHS.orgaoEmissor}
                      element={
                        <RequireAuth>
                          <OrgaoEmissorPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.orgaoEmissorInsert}
                      element={
                        <RequireAuth>
                          <OrgaoEmissorForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.orgaoEmissorEdit()}
                      element={
                        <RequireAuth>
                          <OrgaoEmissorForm />
                        </RequireAuth>
                      }
                    />

                    {/* situação funcional */}
                    <Route
                      path={PATHS.situacaoFuncional}
                      element={
                        <RequireAuth>
                          <SituacaoFuncionalPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.situacaoFuncionalInsert}
                      element={
                        <RequireAuth>
                          <SituacaoFuncionalForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.situacaoFuncionalEdit()}
                      element={
                        <RequireAuth>
                          <SituacaoFuncionalForm />
                        </RequireAuth>
                      }
                    />

                    {/* Interesse educacional */}
                    <Route
                      path={PATHS.interesseEducacional}
                      element={
                        <RequireAuth>
                          <InteresseEducacionalPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.interesseEducacionalInsert}
                      element={
                        <RequireAuth>
                          <InteresseEducacionalForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.interesseEducacionalEdit()}
                      element={
                        <RequireAuth>
                          <InteresseEducacionalForm />
                        </RequireAuth>
                      }
                    />

                    {/* Coordenadoria */}
                    <Route
                      path={PATHS.coordenadoria}
                      element={
                        <RequireAuth>
                          <CoordenadoriaPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.coordenadoriaInsert}
                      element={
                        <RequireAuth>
                          <CoordenadoriaForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.coordenadoriaEdit()}
                      element={
                        <RequireAuth>
                          <CoordenadoriaForm />
                        </RequireAuth>
                      }
                    />
                    {/* Concurso */}
                    <Route
                      path={PATHS.concurso}
                      element={
                        <RequireAuth>
                          <ConcursoPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.concursoInsert}
                      element={
                        <RequireAuth>
                          <ConcursoForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.concursoEdit()}
                      element={
                        <RequireAuth>
                          <ConcursoForm />
                        </RequireAuth>
                      }
                    />

                    {/* meso-regiao */}
                    <Route
                      path={PATHS.mesoRegiao}
                      element={
                        <RequireAuth>
                          <MesoRegiaoPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.mesoRegiaoInsert}
                      element={
                        <RequireAuth>
                          <MesoRegiaoForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.mesoRegiaoEdit()}
                      element={
                        <RequireAuth>
                          <MesoRegiaoForm />
                        </RequireAuth>
                      }
                    />

                    {/* micro-regiao */}
                    <Route
                      path={PATHS.microRegiao}
                      element={
                        <RequireAuth>
                          <MicroRegiaoPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.microRegiaoInsert}
                      element={
                        <RequireAuth>
                          <MicroRegiaoForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.microRegiaoEdit()}
                      element={
                        <RequireAuth>
                          <MicroRegiaoForm />
                        </RequireAuth>
                      }
                    />

                    {/* contato */}
                    <Route
                      path={PATHS.contato}
                      element={
                        <RequireAuth>
                          <ContatoPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.contatoInsert}
                      element={
                        <RequireAuth>
                          <ContatoForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.contatoEdit()}
                      element={
                        <RequireAuth>
                          <ContatoForm />
                        </RequireAuth>
                      }
                    />

                    {/* Vinculo */}
                    <Route
                      path={PATHS.vinculo}
                      element={
                        <RequireAuth>
                          <VinculoPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.vinculoInsert}
                      element={
                        <RequireAuth>
                          <VinculoForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.vinculoEdit()}
                      element={
                        <RequireAuth>
                          <VinculoForm />
                        </RequireAuth>
                      }
                    />

                    {/* Contatos */}
                    <Route
                      path={PATHS.usuario}
                      element={
                        <RequireAuth>
                          <UsuarioPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.usuarioInsert}
                      element={
                        <RequireAuth>
                          <UsuarioForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.usuarioEdit()}
                      element={
                        <RequireAuth>
                          <UsuarioForm />
                        </RequireAuth>
                      }
                    />

                    {/* Comarca */}
                    <Route
                      path={PATHS.comarca}
                      element={
                        <RequireAuth>
                          <ComarcaProvider>
                            <ComarcaPage />
                          </ComarcaProvider>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.comarcaInsert}
                      element={
                        <RequireAuth>
                          <ComarcaProvider>
                            <ComarcaForm />
                          </ComarcaProvider>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.comarcaEdit()}
                      element={
                        <RequireAuth>
                          <ComarcaProvider>
                            <ComarcaForm />
                          </ComarcaProvider>
                        </RequireAuth>
                      }
                    />

                    {/* Lotacao */}
                    <Route
                      path={PATHS.lotacao}
                      element={
                        <RequireAuth>
                          <LotacaoProvider>
                            <LotacaoPage />
                          </LotacaoProvider>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.lotacaoInsert}
                      element={
                        <RequireAuth>
                          <LotacaoProvider>
                            <LotacaoForm />
                          </LotacaoProvider>
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.lotacaoEdit()}
                      element={
                        <RequireAuth>
                          <LotacaoProvider>
                            <LotacaoForm />
                          </LotacaoProvider>
                        </RequireAuth>
                      }
                    />

                    {/* Associado */}
                    <Route
                      path={PATHS.associado}
                      element={
                        <RequireAuth>
                          <AssociadoPage />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.associadoInsert}
                      element={
                        <RequireAuth>
                          <AssociadoForm />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path={PATHS.associadoEdit()}
                      element={
                        <RequireAuth>
                          <AssociadoForm />
                        </RequireAuth>
                      }
                    />

                    {/* unauthorized */}
                    <Route path={PATHS.unauthorized} element={<UnauthorizedPage />} />
                  </Route>

              </Routes>
          </LayoutProvider>
        </AppProvider>
      </AuthProvider>
    </IntlProvider>
  );
}
